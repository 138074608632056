import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';

import CommonFunctions from './CommonFunctions';
import FilterModal from './FilterModal';
import MenuItemImageToolTip from './MenuItemImageToolTip';
import Searchbar from './Searchbar';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext } from '../../AppContext';

const { convertToAbbreviatedNumber } = CommonFunctions;

/**
 * This is a wrapper class for Material UI table component, with added styling
 * and features for selection, filtering, searching, sorting, pagination.
 * @class
 */

/**
 * Returns a FilterIcon (path taken from material.io filter_alt icon)
 */
const FilterIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
    </SvgIcon>
  );
};

const styles = (theme) => ({
  // Table
  tableContainer: {},
  rootTable: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  searchbarContainer: {
    marginBottom: '15px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  showingHowManyRowsHeaderText: {
    fontWeight: 400,
    color: theme.palette.gray0,
    paddingLeft: '10px',
    paddingBottom: '2px',
  },
  tableHeaderText: {
    color: theme.palette.primary2,
    fontSize: '14.75px',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '10.15px',
    },
  },
  tableHeaderCell: {
    padding: '12px 3px 12px 3px',
  },
  tableCellText: {
    ...theme.typography.tableCell,
    color: theme.palette.gray0,
    whiteSpace: 'wrap',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  tableCellStyle: {
    padding: '12px 3px 12px 3px',
    whiteSpace: 'nowrap',
  },
  tableCellCheckboxStyle: {
    padding: '0px 3px 0px 3px',
  },
  tableCellStyleAllowWrap: {
    padding: '12px 5px 12px 5px',
  },
  // Icons
  checkbox: {
    color: 'gainsboro',
    padding: '0px',
  },
  leftHeaderIcon: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      fontSize: '13px',
    },
  },
  rightHeaderIcon: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      fontSize: '18px',
    },
  },
  headerIconButtonRoot: {
    padding: '0px 0px 3px 0px',
  },
  // Pagination
  '@global': {
    '.MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#FFFFFF',
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    '.MuiPaginationItem-page.Mui-selected:hover': {
      backgroundColor: '#FFFFFF',
    },
    '.MuiPaginationItem-page': {
      color: '#D3D3D3',
    },
  },
  paginationContainer: {
    marginTop: '5px',
  },
  paginationButtonEnabled: {
    color: theme.palette.primary.main,
  },
  paginationButtonText: {
    fontWeight: 400,
    textDecoration: 'underline',
  },
  // For Key Overview, reduction color is 'theme.palette.success.main', however the color is too light
  // for station and food item breakdown. Hence, a darker green is used.
  reductionColor: {
    color: '#3e8a4a',
  },
  increaseColor: {
    color: theme.palette.info.main,
  },
  wastePerCoverTooltip: {
    maxWidth: 380,
  },
});

/**
 * Filter array of table rows by the selected station to filter for
 * @param {typedefs.StationServiceWasteAnalysis[]} arrTableRow - Array of stationServiceWasteAnalysis
 * @param {string[]} arrServiceNameSelectedForFiltering - Array of stations selected to filter for
 */
const filterByStation = (arrRow, arrStationSelectedForFiltering) => {
  if (arrStationSelectedForFiltering.length !== 0) {
    return arrRow.filter((row) => arrStationSelectedForFiltering.includes(row.station));
  }
  return arrRow;
};

/**
 * Filter array of table rows by the selected service names to filter for
 * @param {typedefs.MenuItemServiceWasteAnalysis[]} arrTableRow - Array of menuItemServiceWasteAnalysis
 * @param {string[]} arrServiceNameSelectedForFiltering - Array of service names selected to filter for
 */
const filterByService = (arrRow, arrServiceNameSelectedForFiltering) => {
  if (arrServiceNameSelectedForFiltering.length !== 0) {
    return arrRow.filter((row) => arrServiceNameSelectedForFiltering.includes(row.serviceName));
  }
  return arrRow;
};

/**
 * Filter array of table rows by the search value
 * @param {typedefs.StationServiceWasteAnalysis[]|typedefs.MenuItemServiceWasteAnalysis[]} arrTableRow - Array of stationServiceWasteAnalysis or array of menuItemServiceWasteAnalysis
 * @param {string} searchValue - Search value
 */
const filterBySearchBar = (arrTableRow, searchValue) => {
  if (searchValue !== '') {
    return arrTableRow.filter((row) => {
      const searchValueInLowerCase = searchValue.toLowerCase();
      const menuItemNameInputInLowerCase = row.menuItemName.toLowerCase();
      return menuItemNameInputInLowerCase.includes(searchValueInLowerCase);
    });
  }
  return arrTableRow;
};

/**
 * Get unique array of service names/ stations for filter modal
 * @param {typedefs.StationServiceWasteAnalysis[]|typedefs.MenuItemServiceWasteAnalysis[]} arrTableRow - Array of stationServiceWasteAnalysis or array of menuItemServiceWasteAnalysis
 * @param {string} parameterType - Can be 'serviceName' or 'station'
 */
const getArrUniqueElement = (arrTableRow, parameterType) => {
  const arrUniqueElement = [];
  arrTableRow.forEach((tableRow) => {
    if (!arrUniqueElement.includes(tableRow[parameterType])) {
      arrUniqueElement.push(tableRow[parameterType]);
    }
  });
  return arrUniqueElement;
};

const SORT_ORDER = {
  ascending: 'ascending',
  descending: 'descending',
};

const TABLE_LABEL = {
  menuItemName: 'menuItemName',
  serviceName: 'serviceName',
  station: 'station',
  searchBar: 'searchBar',
  weight: 'weight',
  cost: 'cost',
  weightPerCover: 'weightPerCover',
  costPerCover: 'costPerCover',
  weightDifferencePercentage: 'weightDifferencePercentage',
  costDifferencePercentage: 'costDifferencePercentage',
  weightPerCoverDifferencePercentage: 'weightPerCoverDifferencePercentage',
  costPerCoverDifferencePercentage: 'costPerCoverDifferencePercentage',
};

const HEADER_LABEL = {
  weightHeader: 'WEIGHT',
  costHeader: 'COST',
  weightPerCoverHeader: 'WASTE PER COVER',
  costPerCoverHeader: 'COST PER COVER',
  serviceHeader: 'SERVICE',
  stationHeader: 'STATION',
  percentageChangeInWeightHeader: '% CHANGE IN WEIGHT',
  percentageChangeInCostHeader: '% CHANGE IN COST',
  percentageChangeInWeightPerCoverHeader: '% CHANGE IN WASTE PER COVER',
  percentageChangeInCostPerCoverHeader: '% CHANGE IN COST PER COVER',
};

const ICON_LABEL = {
  filter: 'filter',
  sort: 'sort',
};

const SERVICE_TYPE = {
  standard: 'Standard',
  production: 'Production',
  plateWaste: 'Plate Waste',
};

/**
 * Sorts arrFilteredRow according to the sortOrderType
 * @param {typedefs.StationServiceWasteAnalysis[]|typedefs.MenuItemServiceWasteAnalysis[]} arrElement - Array of elements to be sorted
 * @param {string} sortOrderType - Sort by ascending or descending
 * @param {string} label - Label of variable to be sorted
 */
const sortArrElementBySortOrderType = (arrElement, sortOrderType, label) => {
  let sortKey;
  if (label === HEADER_LABEL.weightHeader) {
    sortKey = TABLE_LABEL.weight;
  } else if (label === HEADER_LABEL.costHeader) {
    sortKey = TABLE_LABEL.cost;
  } else if (label === HEADER_LABEL.weightPerCoverHeader) {
    sortKey = TABLE_LABEL.weightPerCover;
  } else if (label === HEADER_LABEL.costPerCoverHeader) {
    sortKey = TABLE_LABEL.costPerCover;
  } else if (label === HEADER_LABEL.percentageChangeInWeightHeader) {
    sortKey = TABLE_LABEL.weightDifferencePercentage;
  } else if (label === HEADER_LABEL.percentageChangeInCostHeader) {
    sortKey = TABLE_LABEL.costDifferencePercentage;
  } else if (label === HEADER_LABEL.percentageChangeInWeightPerCoverHeader) {
    sortKey = TABLE_LABEL.weightPerCoverDifferencePercentage;
  } else if (label === HEADER_LABEL.percentageChangeInCostPerCoverHeader) {
    sortKey = TABLE_LABEL.costPerCoverDifferencePercentage;
  } else {
    return;
  }
  if (sortOrderType === SORT_ORDER.descending) {
    arrElement.sort(
      (rowA, rowB) =>
        (rowB.serviceType.type !== SERVICE_TYPE.plateWaste && typeof rowB[sortKey] === 'number'
          ? rowB[sortKey]
          : -Infinity) -
        (rowA.serviceType.type !== SERVICE_TYPE.plateWaste && typeof rowA[sortKey] === 'number'
          ? rowA[sortKey]
          : -Infinity)
    );
  } else if (sortOrderType === SORT_ORDER.ascending) {
    arrElement.sort(
      (rowA, rowB) =>
        (rowA.serviceType.type !== SERVICE_TYPE.plateWaste && typeof rowA[sortKey] === 'number'
          ? rowA[sortKey]
          : -Infinity) -
        (rowB.serviceType.type !== SERVICE_TYPE.plateWaste && typeof rowB[sortKey] === 'number'
          ? rowB[sortKey]
          : -Infinity)
    );
  }
};

class CustomTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFilterServiceModalOpen: false,
      isFilterStationModalOpen: false,
      searchValue: '',
      arrFilteredRow: [],
      arrServiceNameSelectedForFiltering: [],
      arrStationSelectedForFiltering: [],
      arrAllUniqueServiceName: [],
      arrAllUniqueStation: [],
      page: 1,
      sortOrderType: SORT_ORDER.descending, // null, 'ascending' or 'descending'
      sortHeaderLabel: HEADER_LABEL.weightHeader, // null, or any HEADER_LABEL value
    };
  }

  componentDidMount() {
    const { arrTableRow, toggleValueForWeightCost } = this.props;
    sortArrElementBySortOrderType(
      arrTableRow,
      SORT_ORDER.descending,
      toggleValueForWeightCost === TABLE_LABEL.weight
        ? HEADER_LABEL.weightHeader
        : HEADER_LABEL.costHeader
    );
    const arrAllUniqueServiceName = getArrUniqueElement(arrTableRow, TABLE_LABEL.serviceName);
    const arrAllUniqueStation = getArrUniqueElement(arrTableRow, TABLE_LABEL.station);

    this.setState({
      arrFilteredRow: arrTableRow,
      arrAllUniqueServiceName,
      arrAllUniqueStation,
    });
  }

  componentDidUpdate(prevProps) {
    const { toggleValueForWeightCost, arrTableRow } = this.props;

    // Reset arrFilteredRow to the entire data passed in, clear all filters and search value, and find new
    // arrAllUniqueServiceName and arrAllUniqueStation if arrTableRow changes e.g. if user changes location
    // in location dropdown
    if (arrTableRow !== prevProps.arrTableRow) {
      this.resetToOriginalOutlook();
    }
    if (toggleValueForWeightCost !== prevProps.toggleValueForWeightCost) {
      this.resetSortOrder();
    }
  }

  /**
   * Moves page back by 1 for table pagination
   */
  onClickLeftArrowButton() {
    const { page } = this.state;

    this.setState({ page: page - 1 });
  }

  /**
   * Moves page forward by 1 for table pagination
   */
  onClickRightArrowButton() {
    const { page } = this.state;

    this.setState({ page: page + 1 });
  }

  /**
   * Change current page for table pagination upon clicking on the page number
   * @param {number} newPage - Number of new page
   */
  onChangePagination(event, newPage) {
    this.changePage(newPage);
  }

  /**
   * Selects or deselects a row based on whether it was previously selected or not upon clicking on the rows
   * ToDo: KIV. Note that CustomTable component should receive a function with the name 'onClickTableRow' from the parent components, instead of
   * 'updatedArrSelectedRow'. However, making exception for this case because this will result in the repetition of the same logic code in the parent
   * components, and also a possibly messy code where part of the event handlers are handled in the CustomTable components and part of them in the
   * parent components (Ref: #83)
   * @param {typedefs.StationServiceWasteAnalysis|typedefs.MenuItemServiceWasteAnalysis} row - Object of stationServiceWasteAnalysis or menuItemServiceWasteAnalysis
   */
  onClickTableRow(row) {
    const { arrSelectedRow, updateArrSelectedRow } = this.props;

    let arrUpdatedSelectedRow = [];
    if (!this.isRowSelected(row)) {
      arrUpdatedSelectedRow = arrSelectedRow.concat(row);
    } else {
      arrUpdatedSelectedRow = arrSelectedRow.filter((selectedRow) => {
        return !this.areTwoRowsEqualByComparingKeys(selectedRow, row);
      });
    }
    updateArrSelectedRow(arrUpdatedSelectedRow);
  }

  /**
   * Filter for service or station, or sort by weight or cost when clicked
   * @param {string} iconName - Can be 'filter' or 'sort'
   * @param {string} label - Can be 'SERVICE', 'STATION', 'WEIGHT' or 'COST
   */
  onClickTableHeaderIcon(iconName, label) {
    const { sortOrderType } = this.state;
    const isToDescend = sortOrderType === SORT_ORDER.ascending;

    if (iconName === ICON_LABEL.filter && label === HEADER_LABEL.serviceHeader) {
      this.openFilterServiceModal();
    } else if (iconName === ICON_LABEL.filter && label === HEADER_LABEL.stationHeader) {
      this.openFilterStationModal();
    } else if (
      iconName === ICON_LABEL.sort &&
      (label === HEADER_LABEL.weightHeader ||
        label === HEADER_LABEL.costHeader ||
        label === HEADER_LABEL.percentageChangeInWeightHeader ||
        label === HEADER_LABEL.percentageChangeInCostHeader ||
        label === HEADER_LABEL.weightPerCoverHeader ||
        label === HEADER_LABEL.costPerCoverHeader ||
        label === HEADER_LABEL.percentageChangeInWeightPerCoverHeader ||
        label === HEADER_LABEL.percentageChangeInCostPerCoverHeader)
    ) {
      this.sortByOrder(isToDescend, label);
    }
  }

  /**
   * Calls function setArrElementSelectedForFiltering to set the newly selected service names for filtering. If all services are selected, that means there is no
   * filtering by service name.
   * @param {string[]} arrNewStationSelectedForFiltering - Array of stations selected for filtering
   */
  onClickFilterBySelectedServiceName(arrNewServiceNameSelectedForFiltering) {
    const { arrAllUniqueServiceName } = this.state;

    if (arrNewServiceNameSelectedForFiltering.length === arrAllUniqueServiceName.length) {
      this.setArrElementSelectedForFiltering([], TABLE_LABEL.serviceName);
    } else {
      this.setArrElementSelectedForFiltering(
        arrNewServiceNameSelectedForFiltering,
        TABLE_LABEL.serviceName
      );
    }
    this.closeFilterServiceModal();
  }

  /**
   * Calls function setArrElementSelectedForFiltering to set the newly selected station for filtering. If all stations are selected, that means there is no
   * filtering by station.
   * @param {string[]} arrNewStationSelectedForFiltering - Array of stations selected for filtering
   */
  onClickFilterBySelectedStation(arrNewStationSelectedForFiltering) {
    const { arrAllUniqueStation } = this.state;

    if (arrNewStationSelectedForFiltering.length === arrAllUniqueStation.length) {
      this.setArrElementSelectedForFiltering([], TABLE_LABEL.station);
    } else {
      this.setArrElementSelectedForFiltering(
        arrNewStationSelectedForFiltering,
        TABLE_LABEL.station
      );
    }
    this.closeFilterStationModal();
  }

  onClickCloseFilterServiceModal() {
    this.closeFilterServiceModal();
  }

  onClickCloseFilterStationModal() {
    this.closeFilterStationModal();
  }

  /**
   * Change searchValue in state due to a change in the search input and then calls filterRows
   */
  onChangeSearchBar(event) {
    this.filterRows(event.target.value, TABLE_LABEL.searchBar);
    this.changePage(1);
  }

  /**
   * Adds all existing rows into the selectedRows props if not all rows have been selected
   * Deselects all rows if all rows are already selected
   * ToDo: KIV. Note that CustomTable component should receive a function with the name 'onChangeTableHeader' from the parent components, instead of
   * 'updatedArrSelectedRow'. However, making exception for this case because this will result in the repetition of the same logic code in the parent
   * components, and also a possibly messy code where part of the event handlers are handled in the CustomTable components and part of them in the
   * parent components (Ref: #83)
   */
  onChangeTableHeader(event) {
    const { hasFilterAndSortFunctionAndHeaderIcons, arrTableRow, updateArrSelectedRow } =
      this.props;
    const { arrFilteredRow } = this.state;

    if (event.target.checked) {
      if (hasFilterAndSortFunctionAndHeaderIcons) {
        updateArrSelectedRow(arrFilteredRow);
      } else {
        updateArrSelectedRow(arrTableRow);
      }
    } else {
      updateArrSelectedRow([]);
    }
  }

  /**
   * Sets the array of filtered service names/ stations
   * @param {string[]} arrElementSelectedForFiltering - Array of service names or array of stations selected for filtering
   * @param {string} elementName - Can be 'serviceName' or 'station'
   */
  setArrElementSelectedForFiltering(arrElementSelectedForFiltering, elementName) {
    this.filterRows(arrElementSelectedForFiltering, elementName);
    this.changePage(1);
  }

  openFilterServiceModal() {
    this.setState({
      isFilterServiceModalOpen: true,
    });
  }

  closeFilterServiceModal() {
    this.setState({
      isFilterServiceModalOpen: false,
    });
  }

  openFilterStationModal() {
    this.setState({
      isFilterStationModalOpen: true,
    });
  }

  closeFilterStationModal() {
    this.setState({
      isFilterStationModalOpen: false,
    });
  }

  changePage(newPage) {
    this.setState({
      page: newPage,
    });
  }

  /**
   * This function resets the order of the current array of filtered rows when the weight/cost toggle is changed.
   * It resets the sort order to descending, and the sort header label according to the selected weight/cost toggle.
   */
  resetSortOrder() {
    const { toggleValueForWeightCost } = this.props;
    const { arrFilteredRow } = this.state;
    const defaultSortOrder = SORT_ORDER.descending;
    const sortHeaderLabel =
      toggleValueForWeightCost === TABLE_LABEL.weight
        ? HEADER_LABEL.weightHeader
        : HEADER_LABEL.costHeader;
    sortArrElementBySortOrderType(arrFilteredRow, defaultSortOrder, sortHeaderLabel);
    this.setState({
      arrFilteredRow,
      sortOrderType: defaultSortOrder,
      sortHeaderLabel,
    });
  }

  /**
   * Sets sortOrderType in state to 'ascending' or 'descending' and sorts in descending or ascending order respectively by weight or cost
   * @param {boolean} isAscending - True if current sort state is ascending and desire is to descend, vice versa false if current sort state is descending and desire is to ascend
   * @param {string} label - Name of label to differentiate which values are being sorted
   */
  sortByOrder(isAscending, label) {
    const { arrFilteredRow } = this.state;
    const sortOrderType = isAscending ? SORT_ORDER.descending : SORT_ORDER.ascending;
    sortArrElementBySortOrderType(arrFilteredRow, sortOrderType, label);

    this.setState({
      sortOrderType,
      sortHeaderLabel: label,
      arrFilteredRow,
    });
  }

  /**
   * Reset to original outlook where there is no filtering of the table rows. Sorting will be done based on the selected toggleValueForWeightCost.
   */
  resetToOriginalOutlook() {
    const { arrTableRow, toggleValueForWeightCost } = this.props;
    const arrFilteredRow = arrTableRow;
    const sortOrderType = SORT_ORDER.descending;
    const sortHeaderLabel =
      toggleValueForWeightCost === TABLE_LABEL.weight
        ? HEADER_LABEL.weightHeader
        : HEADER_LABEL.costHeader;
    sortArrElementBySortOrderType(arrFilteredRow, sortOrderType, sortHeaderLabel);
    const arrAllUniqueServiceName = getArrUniqueElement(arrTableRow, TABLE_LABEL.serviceName);
    const arrAllUniqueStation = getArrUniqueElement(arrTableRow, TABLE_LABEL.station);

    this.setState({
      arrFilteredRow,
      arrAllUniqueServiceName,
      arrAllUniqueStation,
      arrServiceNameSelectedForFiltering: [],
      arrStationSelectedForFiltering: [],
      searchValue: '',
      sortOrderType,
      sortHeaderLabel,
    });
  }

  /**
   * Filters out rows that
   * 1) match the search criteria, and
   * 2) match the array of filtered service names, and
   * 3) match the array of filtered stations, and
   * based on the current filtered and/or sorted rows
   * @param {string|string[]} newCriteria - Search criteria (for search bar), or array of filter criteria (for station or service filtering)
   * @param {string} changeType - Can be 'searchBar', 'serviceName' or 'station'
   */
  filterRows(newCriteria, changeType) {
    const { arrTableRow } = this.props;
    const { searchValue, arrServiceNameSelectedForFiltering, arrStationSelectedForFiltering } =
      this.state;

    let newSearchValue = searchValue;
    let newArrServiceNameSelectedForFiltering = arrServiceNameSelectedForFiltering;
    let newArrStationSelectedForFiltering = arrStationSelectedForFiltering;

    if (changeType === TABLE_LABEL.searchBar) {
      newSearchValue = newCriteria;
    } else if (changeType === TABLE_LABEL.serviceName) {
      newArrServiceNameSelectedForFiltering = newCriteria;
    } else {
      newArrStationSelectedForFiltering = newCriteria;
    }
    const arrFilteredRowBySearchbar = filterBySearchBar(arrTableRow, newSearchValue);
    const arrFilteredRowBySearchbarAndService = filterByService(
      arrFilteredRowBySearchbar,
      newArrServiceNameSelectedForFiltering
    );
    const arrFilteredRowBySearchbarAndServiceAndStation = filterByStation(
      arrFilteredRowBySearchbarAndService,
      newArrStationSelectedForFiltering
    );

    this.setState({
      searchValue: newSearchValue,
      arrServiceNameSelectedForFiltering: newArrServiceNameSelectedForFiltering,
      arrStationSelectedForFiltering: newArrStationSelectedForFiltering,
      arrFilteredRow: arrFilteredRowBySearchbarAndServiceAndStation,
    });
  }

  /**
   * Checks if a table row is selected
   * @param {typedefs.StationServiceWasteAnalysis|typedefs.MenuItemServiceWasteAnalysis} row - Row to check if it is selected
   * @returns {boolean} Boolean value to indicate if the row is selected
   */
  isRowSelected(row) {
    const { arrSelectedRow } = this.props;

    const matchedSelectedRow = arrSelectedRow.find((selectedRow) => {
      return this.areTwoRowsEqualByComparingKeys(selectedRow, row);
    });
    if (matchedSelectedRow) {
      return true;
    }
    return false;
  }

  /**
   * Checks if 2 table rows are equal by their id keys
   * @param {typedefs.StationServiceWasteAnalysis|typedefs.MenuItemServiceWasteAnalysis} selectedRow - Selected row
   * @param {typedefs.StationServiceWasteAnalysis|typedefs.MenuItemServiceWasteAnalysis} row - Row
   * @returns {boolean} Boolean value to indicate if selectedRow and row are equal by their id keys
   */
  areTwoRowsEqualByComparingKeys(selectedRow, row) {
    const { arrIdKey } = this.props;

    let areTwoRowsEqual = true;
    arrIdKey.forEach((idKey) => {
      if (selectedRow[idKey] !== row[idKey]) {
        areTwoRowsEqual = false;
      }
    });
    return areTwoRowsEqual;
  }

  renderFilterServiceModal() {
    const { classes } = this.props;
    const {
      isFilterServiceModalOpen,
      arrAllUniqueServiceName,
      arrServiceNameSelectedForFiltering,
    } = this.state;

    return (
      <Dialog
        open={isFilterServiceModalOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            this.closeFilterServiceModal();
          }
        }}
        fullWidth
      >
        <DialogTitle className={classes.modal}>
          <Typography align="center" variant="h3" color="primary">
            FILTER BY SERVICE
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FilterModal
            onClickCancel={() => this.onClickCloseFilterServiceModal()}
            onClickFilter={(arrNewServiceNameSelectedForFiltering) =>
              this.onClickFilterBySelectedServiceName(arrNewServiceNameSelectedForFiltering)
            }
            arrElementSelectedForFiltering={arrServiceNameSelectedForFiltering}
            arrAllElementForSelection={arrAllUniqueServiceName}
          />
        </DialogContent>
      </Dialog>
    );
  }

  renderFilterStationModal() {
    const { classes } = this.props;
    const { isFilterStationModalOpen, arrAllUniqueStation, arrStationSelectedForFiltering } =
      this.state;

    return (
      <Dialog
        open={isFilterStationModalOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            this.closeFilterStationModal();
          }
        }}
        fullWidth
      >
        <DialogTitle className={classes.modal}>
          <Typography align="center" variant="h3" color="primary">
            FILTER BY STATION
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FilterModal
            onClickCancel={() => this.onClickCloseFilterStationModal()}
            onClickFilter={(arrNewStationSelectedForFiltering) =>
              this.onClickFilterBySelectedStation(arrNewStationSelectedForFiltering)
            }
            arrElementSelectedForFiltering={arrStationSelectedForFiltering}
            arrAllElementForSelection={arrAllUniqueStation}
          />
        </DialogContent>
      </Dialog>
    );
  }

  /**
   * Renders the text that tells you how how many rows are display on the current page with the total number of rows. This is used only in menu items table.
   */
  renderShowingHowManyRowsHeaderText() {
    const { rowsPerPage, arrTableRow, hasFilterAndSortFunctionAndHeaderIcons, rowHeader, classes } =
      this.props;
    const { page, arrFilteredRow } = this.state;

    const totalNumber = hasFilterAndSortFunctionAndHeaderIcons
      ? arrFilteredRow.length
      : arrTableRow.length;
    const rangeStart = (page - 1) * rowsPerPage + 1;
    const rangeEnd = page * rowsPerPage > totalNumber ? totalNumber : page * rowsPerPage;

    return (
      <Box>
        <Typography variant="h6" className={classes.showingHowManyRowsHeaderText}>
          {totalNumber > 0
            ? `Showing ${rangeStart}-${rangeEnd} out of ${totalNumber} ${rowHeader}`
            : `Showing 0 ${rowHeader}`}
        </Typography>
      </Box>
    );
  }

  /**
   * Renders filter and sort icons in table header if applicable
   * @param {string} iconName - Can be 'filter' or 'sort'
   * @param {string} label - Can be 'SERVICE', 'STATION'
   */
  renderHeaderIcon(iconName, label) {
    const { classes } = this.props;
    const {
      arrServiceNameSelectedForFiltering,
      arrStationSelectedForFiltering,
      sortOrderType,
      sortHeaderLabel,
    } = this.state;

    if (iconName === ICON_LABEL.filter && label === HEADER_LABEL.serviceHeader) {
      return (
        <IconButton size="small" classes={{ root: classes.headerIconButtonRoot }}>
          <FilterIcon
            className={classes.leftHeaderIcon}
            style={{
              color: arrServiceNameSelectedForFiltering.length === 0 ? '#D3D3D3' : '#102547',
            }}
          />
        </IconButton>
      );
    }
    if (iconName === ICON_LABEL.filter && label === HEADER_LABEL.stationHeader) {
      return (
        <IconButton size="small" classes={{ root: classes.headerIconButtonRoot }}>
          <FilterIcon
            className={classes.leftHeaderIcon}
            style={{
              color: arrStationSelectedForFiltering.length === 0 ? '#D3D3D3' : '#102547',
            }}
          />
        </IconButton>
      );
    }
    if (
      iconName === ICON_LABEL.sort &&
      sortOrderType === SORT_ORDER.descending &&
      label === sortHeaderLabel
    ) {
      return (
        <IconButton size="small" classes={{ root: classes.headerIconButtonRoot }}>
          <ArrowDropDown className={classes.rightHeaderIcon} style={{ color: '#102547' }} />
        </IconButton>
      );
    }
    if (
      iconName === ICON_LABEL.sort &&
      sortOrderType === SORT_ORDER.ascending &&
      label === sortHeaderLabel
    ) {
      return (
        <IconButton size="small" classes={{ root: classes.headerIconButtonRoot }}>
          <ArrowDropUp className={classes.rightHeaderIcon} style={{ color: '#102547' }} />
        </IconButton>
      );
    }
    if (iconName === ICON_LABEL.sort && (sortOrderType === null || label !== sortHeaderLabel)) {
      return (
        <IconButton size="small" classes={{ root: classes.headerIconButtonRoot }}>
          <Grid container direction="column">
            <ArrowDropUp
              className={classes.rightHeaderIcon}
              style={{ marginBottom: '-3px', color: '#D3D3D3' }}
            />
            <ArrowDropDown
              className={classes.rightHeaderIcon}
              style={{ marginTop: '-3px', color: '#D3D3D3' }}
            />
          </Grid>
        </IconButton>
      );
    }
    return null;
  }

  render() {
    const { currency, selectedStartDate, selectedEndDate } = this.context;
    const {
      classes,
      arrTableHeader,
      arrTableRow,
      arrTableAlignment,
      arrTableWrap,
      arrIdKey,
      hasFilterAndSortFunctionAndHeaderIcons,
      hasPagination,
      hasSearchbar,
      hasSelectAllCheckboxInHeader,
      rowHeader,
      arrSelectedRow,
      rowsPerPage,
      toggleValueForWeightCost,
      hasCheckboxInBody,
      isFoodItemsBreakdown,
      isGrouped,
      arrColumnWidth,
    } = this.props;
    const { arrFilteredRow, page, searchValue } = this.state;

    const numberOfRowsSelected = arrSelectedRow ? arrSelectedRow.length : 0;
    const isAllRowsSelected = hasFilterAndSortFunctionAndHeaderIcons
      ? numberOfRowsSelected === arrFilteredRow.length
      : numberOfRowsSelected === arrTableRow.length;
    const isAtLeastOneRowSelected = numberOfRowsSelected > 0;
    const indeterminateCheckbox = !isAllRowsSelected && isAtLeastOneRowSelected;

    let arrTableRowToRender;
    if (hasFilterAndSortFunctionAndHeaderIcons) {
      arrTableRowToRender = arrFilteredRow;
    } else if (isGrouped) {
      const mapParentTableRowByGroup = new Map();
      arrTableRow.forEach((tableRow) => {
        const { group, weight, cost, serviceName } = tableRow;
        if (group === null || group === undefined) {
          mapParentTableRowByGroup.set(tableRow.serviceName, {
            groupName: serviceName,
            weight,
            cost,
          });
          return;
        }
        const parentTableRow = mapParentTableRowByGroup.get(group);
        if (parentTableRow) {
          parentTableRow.arrSubRow.push(tableRow);
          parentTableRow.weight += weight;
          parentTableRow.cost += cost;
        } else {
          mapParentTableRowByGroup.set(group, {
            groupName: group,
            weight,
            cost,
            arrSubRow: [tableRow],
          });
        }
      });
      arrTableRowToRender = Array.from(mapParentTableRowByGroup.values());
    } else {
      arrTableRowToRender = arrTableRow;
    }

    if (hasPagination) {
      arrTableRowToRender = arrTableRowToRender.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      );
    }

    return (
      <Box>
        {/* Search bar */}
        {hasSearchbar ? (
          <Box className={classes.searchbarContainer}>
            <Searchbar
              onChange={(event) => this.onChangeSearchBar(event)}
              searchValue={searchValue}
            />
          </Box>
        ) : null}

        {/* Number of rows */}
        {rowHeader ? this.renderShowingHowManyRowsHeaderText() : null}

        {/* Main table */}
        <TableContainer className={classes.tableContainer} align="center">
          <Table className={classes.rootTable} aria-label="simple-table">
            {/* Table headers */}
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellCheckboxStyle}>
                  {hasSelectAllCheckboxInHeader ? (
                    <Checkbox
                      onChange={(event) => this.onChangeTableHeader(event)}
                      checked={isAllRowsSelected}
                      indeterminate={indeterminateCheckbox}
                      className={classes.checkbox}
                      size="small"
                    />
                  ) : null}
                </TableCell>
                {arrTableHeader.map((tableHeader, index) => (
                  <TableCell
                    key={tableHeader.id}
                    align={arrTableAlignment ? arrTableAlignment[index] : 'center'}
                    className={classes.tableHeaderCell}
                    width={arrColumnWidth ? arrColumnWidth[index] : undefined}
                  >
                    <Typography
                      variant="h6"
                      className={`${tableHeader.id}${tableHeader.iconName}Button ${classes.tableHeaderText}`}
                      onClick={() =>
                        this.onClickTableHeaderIcon(tableHeader.iconName, tableHeader.label)
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: arrTableAlignment[index],
                          alignItems: 'center',
                        }}
                      >
                        {/* Tooltip for waste/cost per cover header */}
                        <Tooltip
                          placement="top-middle"
                          arrow
                          open={
                            isFoodItemsBreakdown &&
                            (tableHeader.id === TABLE_LABEL.weightPerCoverDifferencePercentage ||
                              tableHeader.id === TABLE_LABEL.costPerCoverDifferencePercentage)
                              ? undefined
                              : false
                          }
                          classes={{ tooltip: classes.wastePerCoverTooltip }}
                          title={
                            <Typography variant="caption">
                              <b>No Comparable Value</b> - No viable comparable value exists in the
                              previous period
                              <br />
                              <b>Missing Cover</b> - Cover for either previous or current period has
                              not been filled
                              <br />
                              <b>Not Applicable</b> - Cover data does not apply for this service
                            </Typography>
                          }
                        >
                          <div>
                            {tableHeader.label}
                            {tableHeader.id === TABLE_LABEL.weight && ' (KG)'}
                            {tableHeader.id === TABLE_LABEL.weightPerCover && ' (GRAMS)'}
                            {(tableHeader.id === TABLE_LABEL.cost ||
                              tableHeader.id === TABLE_LABEL.costPerCover) &&
                              ` (${currency})`}
                          </div>
                        </Tooltip>
                        <div>
                          {hasFilterAndSortFunctionAndHeaderIcons &&
                          tableHeader.iconName === ICON_LABEL.filter
                            ? this.renderHeaderIcon(tableHeader.iconName, tableHeader.label)
                            : null}
                          {hasFilterAndSortFunctionAndHeaderIcons &&
                          tableHeader.iconName === ICON_LABEL.sort
                            ? this.renderHeaderIcon(tableHeader.iconName, tableHeader.label)
                            : null}
                        </div>
                      </div>
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table body */}
            <TableBody>
              {arrTableRowToRender.map((tableRow) => {
                const { arrSubRow } = tableRow;
                const isRowSelected = hasCheckboxInBody ? this.isRowSelected(tableRow) : false;
                let rowKey = '';
                arrIdKey.forEach((idKey) => {
                  rowKey = rowKey.concat(
                    `${tableRow[idKey]} ${
                      tableRow.serviceName || tableRow.groupName
                    } ${toggleValueForWeightCost}`
                  );
                });

                return (
                  <TableRow
                    selected={isRowSelected}
                    {...(hasCheckboxInBody && { onClick: () => this.onClickTableRow(tableRow) })}
                    key={rowKey}
                  >
                    <TableCell className={classes.tableCellCheckboxStyle}>
                      {hasCheckboxInBody ? (
                        <Checkbox
                          checked={isRowSelected}
                          className={classes.checkbox}
                          size="small"
                        />
                      ) : null}
                    </TableCell>
                    {arrTableHeader.map((tableHeader, index) => {
                      const cellContent = (
                        <>
                          <Typography
                            variant="body2"
                            className={`${classes.tableCellText} ${
                              [
                                TABLE_LABEL.costDifferencePercentage,
                                TABLE_LABEL.weightDifferencePercentage,
                                TABLE_LABEL.weightPerCoverDifferencePercentage,
                                TABLE_LABEL.costPerCoverDifferencePercentage,
                              ].includes(tableHeader.id) &&
                              typeof tableRow[tableHeader.id] === 'number' &&
                              (tableRow[tableHeader.id] < 0
                                ? classes.reductionColor
                                : classes.increaseColor)
                            }`}
                          >
                            {(tableHeader.id === TABLE_LABEL.costDifferencePercentage ||
                              tableHeader.id === TABLE_LABEL.costPerCoverDifferencePercentage) && (
                              <span>{tableRow[tableHeader.id] > 0 && '+'}</span>
                            )}
                            {(tableHeader.id === TABLE_LABEL.weightDifferencePercentage ||
                              tableHeader.id ===
                                TABLE_LABEL.weightPerCoverDifferencePercentage) && (
                              <span>{tableRow[tableHeader.id] > 0 && '+'}</span>
                            )}
                            {[
                              TABLE_LABEL.cost,
                              TABLE_LABEL.weight,
                              TABLE_LABEL.costDifferencePercentage,
                              TABLE_LABEL.weightDifferencePercentage,
                              TABLE_LABEL.weightPerCover,
                              TABLE_LABEL.costPerCover,
                              TABLE_LABEL.weightPerCoverDifferencePercentage,
                              TABLE_LABEL.costPerCoverDifferencePercentage,
                            ].includes(tableHeader.id) &&
                            typeof tableRow[tableHeader.id] === 'number'
                              ? convertToAbbreviatedNumber(tableRow[tableHeader.id])
                              : tableRow[tableHeader.id]}
                          </Typography>
                          {arrSubRow
                            ? arrSubRow.map((subRow) => {
                                const { serviceName } = subRow;
                                return (
                                  <Typography
                                    variant="subtitle2"
                                    key={`${rowKey}_${serviceName}_grouped`}
                                  >
                                    {index === 0
                                      ? `• ${serviceName}`
                                      : convertToAbbreviatedNumber(subRow[tableHeader.id])}
                                  </Typography>
                                );
                              })
                            : null}
                        </>
                      );
                      return (
                        <TableCell
                          className={`${tableHeader.id} ${
                            arrTableWrap && arrTableWrap[index]
                              ? classes.tableCellStyleAllowWrap
                              : classes.tableCellStyle
                          }`}
                          align={arrTableAlignment ? arrTableAlignment[index] : 'center'}
                          key={[tableHeader.id]}
                        >
                          {isFoodItemsBreakdown && tableHeader.id === TABLE_LABEL.menuItemName ? (
                            <MenuItemImageToolTip
                              selectedStartDate={selectedStartDate}
                              selectedEndDate={selectedEndDate}
                              tableRow={tableRow}
                              toggleValueForWeightCost={toggleValueForWeightCost}
                              cellContent={cellContent}
                            />
                          ) : (
                            cellContent
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        {hasPagination ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            className={classes.paginationContainer}
          >
            <Grid item>
              <Button
                onClick={() => this.onClickLeftArrowButton()}
                disabled={page === 1}
                classes={{
                  root: classes.paginationButtonEnabled,
                }}
              >
                <Typography variant="h6" className={classes.paginationButtonText}>
                  &lt; PREV
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Pagination
                count={
                  hasFilterAndSortFunctionAndHeaderIcons
                    ? Math.ceil(arrFilteredRow.length / rowsPerPage)
                    : Math.ceil(arrTableRow.length / rowsPerPage)
                }
                size="small"
                onChange={(event, newPage) => this.onChangePagination(event, newPage)}
                page={page}
                hideNextButton
                hidePrevButton
                siblingCount={1}
                variant="text"
                boundaryCount={2}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={() => this.onClickRightArrowButton()}
                disabled={
                  hasFilterAndSortFunctionAndHeaderIcons
                    ? page >= Math.ceil(arrFilteredRow.length / rowsPerPage)
                    : page >= Math.ceil(arrTableRow.length / rowsPerPage)
                }
                classes={{
                  root: classes.paginationButtonEnabled,
                }}
              >
                <Typography variant="h6" className={classes.paginationButtonText}>
                  NEXT &gt;
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {/* Filter modals */}
        {hasFilterAndSortFunctionAndHeaderIcons ? this.renderFilterServiceModal() : null}
        {hasFilterAndSortFunctionAndHeaderIcons ? this.renderFilterStationModal() : null}
      </Box>
    );
  }
}

CustomTable.contextType = AppContext;

export default withStyles(styles)(CustomTable);
