import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { AppContext, CONSTANT } from '../../../AppContext';
import TrendLine from '../../common/chart/TrendLine';
import LineChart from '../../common/chart/LineChart';
import BarChart from '../../common/chart/BarChart';

import CommonFunctions from '../../common/CommonFunctions';

const { convertToAbbreviatedNumber } = CommonFunctions;

class WeightCostShowMore extends PureComponent {
  /**
   * Get chart title based on the selected group by
   */
  getWastageTitleForLineChart(classes) {
    const { selectedGroupBy } = this.context;

    let chartTitle = '';

    if (selectedGroupBy === CONSTANT.groupByDay) {
      chartTitle = 'WASTAGE BY DAY';
    } else if (selectedGroupBy === CONSTANT.groupByWeek) {
      chartTitle = 'WASTAGE BY WEEK';
    } else {
      chartTitle = 'WASTAGE BY MONTH';
    }

    return <Typography className={classes.chartTitle}>{chartTitle}</Typography>;
  }

  /**
   * Create data structure for trendline
   * @param {typedefs.WasteAnalysisForLineChartForKeyOverview} axesData - Waste analysis for line chart
   * @param {number} trendValue - Percentage change from previous data range by weight
   */
  createDataStructureForTrendLine(axesData, trendValue) {
    const { tabValue } = this.props;

    const isTrendIncrease = trendValue > 0;
    const color = isTrendIncrease ? CONSTANT.keyOverviewCoralColor : CONSTANT.keyOverviewGreenColor;

    const result = {
      labels: axesData.arrDate,
      datasets: [
        {
          data: tabValue === 'weight' ? axesData.arrWeightAndGoal[0] : axesData.arrCost,
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          borderColor: color,
        },
      ],
    };
    return result;
  }

  render() {
    const height = 50;
    const width = '95%';
    const {
      classes,
      displayTotalWastageTooltipText,
      imageIcon,
      isTooltipOpened,
      onClickAwayTooltip,
      onClickInfoIcon,
      onCloseTooltip,
      showMoreValue,
      SHOW_MORE_VALUE,
      wasteAnalysisForKeyOverview,
    } = this.props;
    const { currency } = this.context;
    return (
      <div className={classes.tabPanel}>
        <Grid container alignItems="center" spacing={1}>
          <Grid container direction="row" spacing={1} alignItems="baseline">
            <Grid item>
              <Typography variant="h1">
                {`Total ${
                  showMoreValue === SHOW_MORE_VALUE.totalWeight ? 'Weight' : 'Cost'
                } of Waste`}
              </Typography>
            </Grid>
            <Grid item>
              <ClickAwayListener onClickAway={onClickAwayTooltip}>
                <Tooltip
                  arrow
                  onClose={onCloseTooltip}
                  open={isTooltipOpened}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={displayTotalWastageTooltipText(
                    `${showMoreValue === SHOW_MORE_VALUE.totalWeight ? 'weight' : 'cost'}`
                  )}
                >
                  <InfoIcon onClick={onClickInfoIcon} className={classes.tooltipIcon} />
                </Tooltip>
              </ClickAwayListener>
            </Grid>
          </Grid>
          <Grid item>
            <img src={imageIcon} className={classes.imageWeightCost} alt="weightCostIcon" />
            <Typography
              variant="subtitle1"
              display="inline"
              className={`${
                showMoreValue === SHOW_MORE_VALUE.totalWeight
                  ? 'totalWeightValue'
                  : 'totalCostValue'
              }`}
            >
              {showMoreValue === SHOW_MORE_VALUE.totalWeight
                ? convertToAbbreviatedNumber(wasteAnalysisForKeyOverview.weight)
                : convertToAbbreviatedNumber(wasteAnalysisForKeyOverview.cost)}
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              className={`${
                showMoreValue === SHOW_MORE_VALUE.totalWeight
                  ? 'totalWeightValueUnit'
                  : 'totalCostValueUnit'
              } ${classes.totalUnitGridItem}`}
            >
              {showMoreValue === SHOW_MORE_VALUE.totalWeight ? CONSTANT.weightKGUnit : currency}
            </Typography>
          </Grid>

          {wasteAnalysisForKeyOverview.percentageChange && (
            <Grid item>
              <Typography
                className={`${
                  showMoreValue === SHOW_MORE_VALUE.totalWeight
                    ? 'totalWeightPercentage'
                    : 'totalCostPercentage'
                } ${
                  (showMoreValue === SHOW_MORE_VALUE.totalWeight &&
                    wasteAnalysisForKeyOverview.percentageChange.byWeight > 0) ||
                  (showMoreValue === SHOW_MORE_VALUE.totalCost &&
                    wasteAnalysisForKeyOverview.percentageChange.byCost > 0)
                    ? classes.trendDecrease
                    : classes.trendIncrease
                }`}
                variant="h5"
              >
                {showMoreValue === SHOW_MORE_VALUE.totalWeight
                  ? `${
                      wasteAnalysisForKeyOverview.percentageChange.byWeight > 0 ? '+' : ''
                    }${convertToAbbreviatedNumber(
                      wasteAnalysisForKeyOverview.percentageChange.byWeight
                    )}`
                  : `${
                      wasteAnalysisForKeyOverview.percentageChange.byCost > 0 ? '+' : ''
                    }${convertToAbbreviatedNumber(
                      wasteAnalysisForKeyOverview.percentageChange.byCost
                    )}`}
                <Typography
                  variant="body2"
                  display="inline"
                  className={`${
                    showMoreValue === SHOW_MORE_VALUE.totalWeight
                      ? 'totalWeightPercentageUnit'
                      : 'totalCostPercentageUnit'
                  }`}
                >
                  %
                </Typography>
              </Typography>
            </Grid>
          )}

          <Grid item xs className={classes.trendlineGridItem}>
            <TrendLine
              chartData={
                showMoreValue === SHOW_MORE_VALUE.totalWeight
                  ? this.createDataStructureForTrendLine(
                      wasteAnalysisForKeyOverview.wasteAnalysisForLineChart,
                      wasteAnalysisForKeyOverview.percentageChange.byWeight
                    )
                  : this.createDataStructureForTrendLine(
                      wasteAnalysisForKeyOverview.wasteAnalysisForLineChart,
                      wasteAnalysisForKeyOverview.percentageChange.byCost
                    )
              }
              height={height}
              width={width}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid>
            <Grid item>
              <Grid item className={classes.wastageChartGrid}>
                {this.getWastageTitleForLineChart(classes)}
              </Grid>
              <Grid item>
                {showMoreValue === SHOW_MORE_VALUE.totalWeight ? (
                  <LineChart
                    xAxisData={wasteAnalysisForKeyOverview.wasteAnalysisForLineChart.arrDate}
                    yAxisData={[
                      wasteAnalysisForKeyOverview.wasteAnalysisForLineChart.arrWeightAndGoal[0], // Restaurant Goal is removed for the time being
                    ]}
                    yAxisLabel="WEIGHT (KG)"
                    labelData={['Weight']} // labelData={['Weight', 'Goal']} Restaurant Goal is removed for the time being
                    isInKeyOverview
                    hasVisibleNodes={false}
                    hasGoal
                  />
                ) : (
                  <LineChart
                    xAxisData={wasteAnalysisForKeyOverview.wasteAnalysisForLineChart.arrDate}
                    yAxisData={[wasteAnalysisForKeyOverview.wasteAnalysisForLineChart.arrCost]}
                    yAxisLabel={`COST (${currency})`}
                    labelData={['Cost']}
                    isInKeyOverview
                    hasVisibleNodes={false}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item>
              <Grid item className={classes.wastagePerfChartGrid}>
                <Typography className={classes.chartTitle}>
                  {showMoreValue === SHOW_MORE_VALUE.totalWeight
                    ? 'WASTAGE PERFORMANCE BY PROPERTY'
                    : 'WASTAGE PERFORMANCE OVER TIME'}
                </Typography>
              </Grid>
              <Grid item>
                <BarChart
                  xAxisData={
                    wasteAnalysisForKeyOverview.wasteAnalysisForBarChart.xAxisArrRestaurantName
                  }
                  yAxisData={
                    showMoreValue === SHOW_MORE_VALUE.totalWeight
                      ? [
                          wasteAnalysisForKeyOverview.wasteAnalysisForBarChart
                            .yAxisArrPercentageChangeByWeightForRestaurant,
                        ]
                      : [
                          wasteAnalysisForKeyOverview.wasteAnalysisForBarChart
                            .yAxisArrPercentageChangeByCostForRestaurant,
                        ]
                  }
                  yAxisLabel="% CHANGE"
                  labelData={[]}
                  isRestaurantPerformance
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

WeightCostShowMore.contextType = AppContext;

export default WeightCostShowMore;
