import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { AppContext } from '../../AppContext';
import CommonFunctions from './CommonFunctions';

const { convertToAbbreviatedNumber } = CommonFunctions;

const styles = (theme) => ({
  container: {
    // maxHeight: 200,
  },
  table: {
    'table-layout': 'fixed',
  },
  colHeader: {
    'border-bottom-style': 'solid',
    'border-bottom': '2px',
    padding: '4px',
  },
  colWidth: {
    width: '25%',
  },
  rowHeader: {
    'font-size': 'medium',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      'font-size': 'smaller',
    },
    textWrap: 'nowrap',
    padding: '4px 10px',
  },
  rowSubHeader: {
    'border-bottom': '0px',
  },
  cellNegativeReduction: {
    color: theme.palette.info.main,
  },
  cellPositiveReduction: {
    color: theme.palette.success.main,
  },
  rowSubHeader1Text: {
    color: theme.palette.secondary.main,
    'font-size': 'medium',
    'font-weight': 'bold',
  },
  rowSubHeader2Text: {
    color: theme.palette.secondary.main,
    'font-size': 'medium',
    'font-weight': 'semi-bold',
  },
  whiteText: {
    color: 'white',
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '15px',
    paddingRight: '5px',
  },
});

class KeyOverviewTable extends PureComponent {
  /**
   * This function checks the class to be given to the cell element depending on value
   * @param {number} value - Number to be determined as positive, negative or neutral
   * @returns Style Class - Class to be given to element
   */
  getIncreaseOrDecreaseTrendClass(value) {
    const { classes } = this.props;
    if (value < 0) {
      return classes.cellPositiveReduction;
    }
    if (value > 0) {
      return classes.cellNegativeReduction;
    }

    return classes.whiteText;
  }

  render() {
    const { classes, tableHeaderCategory, arrTableRow, showMoreValue, formatValue } = this.props;

    return (
      <TableContainer className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.colHeader} ${classes.whiteText}`} />
              <TableCell
                className={`${classes.colHeader} ${classes.colWidth} ${classes.whiteText}`}
                align="center"
              >
                {tableHeaderCategory}
              </TableCell>
              <TableCell
                className={`${classes.colHeader} ${classes.colWidth} ${classes.whiteText}`}
                align="center"
              >
                Percentage (%)
              </TableCell>
            </TableRow>
          </TableHead>
          {arrTableRow.map((row) => (
            <TableBody key={row.restaurantName}>
              {row.restaurantName && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    className={`location ${classes.rowSubHeader} ${classes.rowSubHeader1Text}`}
                    size="small"
                    colSpan={3}
                  >
                    {row.restaurantName}
                  </TableCell>
                </TableRow>
              )}
              {row.locationName && (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    className={`location ${classes.rowSubHeader} ${classes.rowSubHeader2Text}`}
                    size="small"
                    colSpan={3}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{row.locationName}
                  </TableCell>
                </TableRow>
              )}
              {row.arrWasteReductionAnalysis.map((wasteReductionAnalysis) => (
                <TableRow key={wasteReductionAnalysis.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={`location ${classes.rowHeader} ${classes.whiteText}`}
                    size="small"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{`${wasteReductionAnalysis.name}`}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`value ${this.getIncreaseOrDecreaseTrendClass(
                      wasteReductionAnalysis.value
                    )}`}
                    size="small"
                  >
                    {formatValue(showMoreValue, wasteReductionAnalysis.value)}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`percentage ${this.getIncreaseOrDecreaseTrendClass(
                      wasteReductionAnalysis.valueInPercentage
                    )}`}
                    size="small"
                  >
                    {`${wasteReductionAnalysis.valueInPercentage > 0 ? '+' : ''}${
                      wasteReductionAnalysis.valueInPercentage === '-'
                        ? '-'
                        : convertToAbbreviatedNumber(wasteReductionAnalysis.valueInPercentage)
                    }`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    );
  }
}

KeyOverviewTable.contextType = AppContext;

export default withRouter(withTheme(withStyles(styles)(KeyOverviewTable)));
