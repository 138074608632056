import axios from 'axios';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { Calendar } from 'react-multi-date-picker';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
// Below required as CellChange is referred to in parameter function description
// eslint-disable-next-line no-unused-vars
import { CellChange, Column, ReactGrid, Row } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';

// Below required as typedefs is referred to in parameter function description
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { AppContext, CONSTANT } from '../../AppContext';

/**
 * Callback for updating monthYear
 * @callback UpdateMonthYearCallback
 * @param {number | DateObject | DateObject[] | null} updateMonthYearValue - Value passed into callback to select monthYear
 */

const styles = (theme) => ({
  buttonHeaderRow: {
    position: 'relative',
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  calendarBox: {
    margin: '65px 0px 0px 50px',
    position: 'absolute',
    zIndex: 999,
  },
  warningTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: theme.palette.info.main,
  },
  buttonStyle: {
    boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.12)',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    padding: '6px 18px',
  },
  saveChangesButtonStyle: {
    boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.12)',
    borderRadius: '12px',
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '6px 18px',
    '& .MuiButton-startIcon': {
      marginRight: '6px',
      color: theme.palette.primary2,
    },
    float: 'right',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      color: '#ffffff',
    },
  },
  confirmButton: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
    '&:hover': {
      color: '#000000',
    },
  },
  tableCellForInvalidDate: {
    background: theme.palette.gray3,
  },
  tableCellWithNewValue: {
    color: '#03C04A',
    fontWeight: 'bold',
  },
  tableCellForTotalCover: {
    fontWeight: 'bold',
  },
  // ReactGrid is capsulated within two grids with two different styling. The ReactGrid outer grid
  // styling and inner grid styling is that the former is to set the cover input table in the center,
  // while the latter is to set the ReactGrid to start displaying from the first column regardless of
  // the zoom level. The main reason for having two grids is because if there is only one grid, setting
  // the alignment of the ReactGrid to center causes part of the first column of the ReactGrid to be cut
  // off when the browwser is too zoomed in.
  reactGridOuterGrid: {
    justifyContent: 'center',
    position: 'relative',
    padding: '10px 0px 20px 0px',
    width: 'fit-content',
  },
  reactGridInnerGrid: {
    justifyContent: 'flex-start',
    padding: '10px 0px 20px 0px',
    overflowX: 'scroll',
  },
  tooltipIcon: {
    ...theme.typography.h2,
    position: 'absolute',
    zIndex: 99,
    top: '-6px',
    right: 0,
  },
});

const ARR_SERVICE_ORDER = [
  'all day',
  'all day leftover',
  'all day dining',
  'all day dining leftover',
  'all day buffet',
  'all day buffet leftover',
  'breakfast',
  'breakfast buffet',
  'breakfast buffet leftover',
  'breakfast buffet leftovers',
  'breakfast banquet',
  'breakfast leftovers',
  'breakfast leftover',
  'food to charity',
  'breakfast food to charity',
  'food to donation',
  'f2c breakfast',
  'f2sc breakfast',
  'food to staff canteen',
  'rework breakfast',
  'am coffee break',
  'brunch',
  'brunch buffet',
  'brunch buffet leftover',
  'sunday brunch',
  'sunday brunch leftover',
  'sunday brunch buffet leftover',
  'lunch',
  'lunch buffet',
  'lunch buffet leftover',
  'lunch banquet',
  'lunch banquet leftover',
  'lunch and dinner',
  'f2c lunch',
  'f2sc lunch',
  'rework lunch',
  'afternoon tea',
  'afternoon tea leftover',
  'pm coffee break',
  'tea',
  'dinner',
  'dinner buffet',
  'dinner buffet leftover',
  'dinner banquet',
  'dinner banquet leftover',
  'Dinner food to charity',
  'f2c dinner',
  'f2sc dinner',
  'rework dinner',
  'supper',
  'production',
  'spoilt inventory',
  'delivery supply',
];

const DELETE_COVER_INDICATOR = -10;
const EXCLUDE_WASTE_INDICATOR = -1;

/**
 * Function checks if there is any cover change for a service
 * @param {number} columnIndex - Index of column. Use for searching for row cells that belongs to the service.
 * @param {Row[]} arrRow - Array of row, containing row information for each table row cell
 * @param {{ value: number, coverId: number }[]} arrUnchangedCover - Array of unchanged cover that belongs to the service
 * @returns {boolean} boolean - Indicates if there is any cover changes for the service
 */
const checkForCoverChangeIfZeroTotalCover = (columnIndex, arrRow, arrUnchangedCover) => {
  const arrRowWithoutHeader = arrRow.slice(2);
  for (let rowIndex = 0; rowIndex < arrRowWithoutHeader.length; rowIndex += 1) {
    if (
      arrUnchangedCover[rowIndex] &&
      arrRowWithoutHeader[rowIndex].cells[columnIndex].value !== arrUnchangedCover[rowIndex].value
    ) {
      return false;
    }
  }
  return true;
};

/**
 * Function destructures arrRestaurantService into arrLocationWithNonProductionService, arrNonProductionService and mapProjectStartDateByServiceId.
 * Production services are filtered.
 * @param {typedefs.RestaurantService[]} arrRestaurantService - Array of restaurantService belonging to the user
 * @returns {Object} Object
 * @returns {typedefs.LocationService[]} Object.arrLocationWithNonProductionService - Array of all locationService, excluding production services, belonging to the user
 * @returns {typedefs.Service[]} Object.arrNonProductionService - Array of all service, excluding production services, belonging to the user
 * @returns {Map<string, string>} Object.mapProjectStartDateByServiceId - Map of all project start dates belonging to all non-production services
 * to be used by this.getArrayTableRowData() to restrict cover input before service start date
 */
const getArrLocationServiceArrServiceAndMapProjectStartDateByServiceId = (arrRestaurantService) => {
  const arrLocationWithNonProductionService = [];
  const arrNonProductionService = [];
  const mapProjectStartDateByServiceId = new Map();
  arrRestaurantService.forEach((restaurantService) => {
    restaurantService.arrLocationService.forEach((locationService) => {
      const arrServiceOfALocation = [];
      locationService.arrService.forEach((service) => {
        if (service.serviceType.type !== 'Production') {
          const { serviceId, projectStartDate } = service;
          mapProjectStartDateByServiceId.set(serviceId, projectStartDate);
          arrServiceOfALocation.push(service);
        }
      });
      if (arrServiceOfALocation.length !== 0) {
        const locationWithNonProductionService = {
          ...locationService,
          arrService: arrServiceOfALocation,
        };
        arrNonProductionService.push(...arrServiceOfALocation);
        arrLocationWithNonProductionService.push(locationWithNonProductionService);
      }
    });
  });
  return {
    arrLocationWithNonProductionService,
    arrNonProductionService,
    mapProjectStartDateByServiceId,
  };
};

/**
 * Function loops through arrLocationWithNonProductionService to get the header details for constructing the header rows (location names and
 * service names) in the table, the array of columns for the table and a map (of key: columnId and value: column index) for identifying which
 * cell is updated in updateTable function, and a set of serviceId to record the unique location-service pair for recording changed cells in
 * updateTable function.
 * In addition, mapIsValidByServiceId is returned to be able to conditionally set edit permissions for invalid services in getArrayTableRowData function.
 * @param {typedefs.LocationService[]} arrLocationWithNonProductionService - Array of all locationService of the restaurant, excluding production service, that the user has access to
 * @param {Map<string, number[]>} Object.mapArrExcludedNonProductionServiceIdByLocationIdServiceGroupString - Map of excluded non-production serviceId (excluded by the Backend logic) that has a group. This is used for creating, deleting or updating a service group where it has at least 1 excluded service.
 * @returns {Column[]} arrColumn - Array of column, containing information about the header cell of that column
 * @returns {Row[]} arrHeaderRow - Array of all service of the selected location
 * @returns {Row[]} arrHeaderRow[0] - Header row for location
 * @returns {Row[]} arrHeaderRow[1] - Header row for service
 * @returns {Set<number>} setServiceId - Set of serviceId
 * @returns {Map<string, string>} mapColumnIndexByColumnId - Map of key: columnId (serviceId) and value: column index
 * @returns {Map<number, boolean>} mapIsValidByServiceId - Map of key: serviceId and value: isValid
 */
const getHeaderDetails = (
  arrLocationWithNonProductionService,
  mapArrExcludedNonProductionServiceIdByLocationIdServiceGroupString
) => {
  const setServiceId = new Set();
  const mapColumnIndexByColumnId = new Map();
  const mapIsValidByServiceId = new Map();
  const mapArrServiceIdByColumnId = new Map();
  const arrColumn = [{ columnId: 'Date', width: 150 }];
  const locationHeaderRow = {
    rowId: 'location_header',
    cells: [{ type: 'header', text: 'Date', rowspan: 2 }],
  };
  const serviceHeaderRow = {
    rowId: 'service_header',
    cells: [{ type: 'header', text: '' }],
  };
  let columnIndex = 1;
  arrLocationWithNonProductionService.forEach((locationService) => {
    const mapArrServiceIdByGroup = new Map();
    const { locationId, name: locationName, arrService } = locationService;
    const arrServiceGrouped = [];
    arrService.forEach((service) => {
      const { serviceId, group } = service;
      if (group === null || group === undefined) {
        arrServiceGrouped.push(service);
      } else if (!mapArrServiceIdByGroup.has(group)) {
        arrServiceGrouped.push({ ...service, name: service.group });
        mapArrServiceIdByGroup.set(group, [serviceId]);
      } else {
        mapArrServiceIdByGroup.get(group).push(serviceId);
      }
    });
    mapArrServiceIdByGroup.forEach((arrServiceId, group) => {
      const arrServiceIdOfSameGroupExceptFirstId = arrServiceId.slice(1);
      // Add excluded service has that the same group to the arrServiceId by the specific columnId
      const arrExcludedNonProductionServiceId =
        mapArrExcludedNonProductionServiceIdByLocationIdServiceGroupString.get(
          `${locationId}___${group}`
        );
      if (arrExcludedNonProductionServiceId) {
        arrServiceIdOfSameGroupExceptFirstId.push(...arrExcludedNonProductionServiceId);
      }
      mapArrServiceIdByColumnId.set(arrServiceId[0], arrServiceIdOfSameGroupExceptFirstId);
    });
    // Sort services by their names based on ARR_SERVICE_ORDER
    // If service name does not exist in ARR_SERVICE_ORDER, then give it an Infinity ranking to be pushed to the back
    arrServiceGrouped.sort((serviceA, serviceB) => {
      const serviceAIndex = ARR_SERVICE_ORDER.indexOf(serviceA.name.toLowerCase());
      const serviceBIndex = ARR_SERVICE_ORDER.indexOf(serviceB.name.toLowerCase());
      return (
        (serviceAIndex > -1 ? serviceAIndex : Infinity) -
        (serviceBIndex > -1 ? serviceBIndex : Infinity)
      );
    });
    // This is done to create empty header cells if the location has more than 1 service
    // and has to span over the number of service header cells it has
    arrServiceGrouped.forEach((service, index) => {
      let locationHeaderRowCell = null;
      if (index === 0) {
        locationHeaderRowCell = {
          type: 'header',
          text: locationName,
          colspan: arrServiceGrouped.length,
          className: 'locationHeaderCell',
        };
      } else {
        locationHeaderRowCell = {
          type: 'header',
          text: '',
        };
      }
      locationHeaderRow.cells.push(locationHeaderRowCell);
    });

    arrServiceGrouped.forEach((service) => {
      const { name: serviceName, serviceId, isValid } = service;

      const serviceheaderRowCell = {
        type: 'header',
        text: serviceName,
        className: 'serviceHeaderCell',
      };
      serviceHeaderRow.cells.push(serviceheaderRowCell);

      const columnId = serviceId;
      const column = {
        columnId,
        width: 150,
      };
      arrColumn.push(column);
      mapColumnIndexByColumnId.set(columnId, columnIndex);
      columnIndex += 1;
      setServiceId.add(columnId);
      mapIsValidByServiceId.set(serviceId, isValid);
    });
  });

  const arrHeaderRow = [locationHeaderRow, serviceHeaderRow];
  return {
    arrColumn,
    arrHeaderRow,
    setServiceId,
    mapColumnIndexByColumnId,
    mapIsValidByServiceId,
    mapArrServiceIdByColumnId,
  };
};

/**
 * Function loops through arrServiceIdCover retrieved from Backend and converts the data into a map of key: date and
 * value: mapCoverByServiceId
 * @param {Object[]} arrServiceIdCover - Array containing objects of serviceId and its array of cover objects, where it has all the service that the user has access to
 * @returns {Map<string, Map<string, {value: number, coverId: number}>>} mapMapCoverByServiceIdByDate - Map of key: date and value: mapCoverByServiceId
 */
const getMapMapCoverByServiceIdByDate = (arrServiceIdCover) => {
  const mapMapCoverByServiceIdByDate = new Map();
  arrServiceIdCover.forEach((serviceIdCover) => {
    serviceIdCover.arrCover.forEach((cover) => {
      const { date, value, coverId, serviceId } = cover;
      if (mapMapCoverByServiceIdByDate.get(date)) {
        mapMapCoverByServiceIdByDate.set(
          date,
          mapMapCoverByServiceIdByDate.get(date).set(serviceId, { value, coverId })
        );
      } else {
        mapMapCoverByServiceIdByDate.set(date, new Map([[serviceId, { value, coverId }]]));
      }
    });
  });

  return mapMapCoverByServiceIdByDate;
};

/**
 * Function updates the arrServiceIdCover with the cover(s) modified
 * Note: DELETE_COVER_INDICATOR is used to indicate that the cover is to be deleted
 * @param {typedefs.Cover[]} arrServiceIdCover[].arrCover - Array of cover objects successfully modified
 * @param {Object[]} arrServiceIdCover - Array containing an object of serviceId and its array of cover objects before modifying cover(s)
 * @param {number} arrServiceIdCover[].serviceId - Id of service
 * @param {typedefs.Cover[]} arrServiceIdCover[].arrCover - Array of cover objects belonging to the service modifying cover(s)
 * @returns {Object[]} updatedArrServiceIdCover - Array containing an object of serviceId and its array of cover objects including cover(s) modified after successful submission
 * @returns {number} updatedArrServiceIdCover[].serviceId - Id of service
 * @returns {typedefs.Cover[]} updatedArrServiceIdCover[].arrCover - Array of cover objects including the cover(s) modified after successful submission belonging to the service
 */
const updateArrServiceIdCoverAfterModifying = (arrCover, arrServiceIdCover) => {
  const updatedArrServiceIdCover = arrServiceIdCover.slice();
  arrCover.forEach((cover) => {
    const matchedServiceIdCoverIndex = updatedArrServiceIdCover.findIndex(
      (serviceIdCover) => serviceIdCover.serviceId === cover.serviceId
    );
    // If serviceId exists in serviceIdCoverIndex
    if (matchedServiceIdCoverIndex !== -1) {
      const matchedCoverIndex = updatedArrServiceIdCover[
        matchedServiceIdCoverIndex
      ].arrCover.findIndex((existingCover) => existingCover.date === cover.date);
      if (matchedCoverIndex !== -1) {
        // If cover is being deleted
        if (cover.value === DELETE_COVER_INDICATOR) {
          updatedArrServiceIdCover[matchedServiceIdCoverIndex].arrCover.splice(
            matchedCoverIndex,
            1
          );
          // If cover is deleted and arrCover for serviceIdCover is empty, delete serviceIdCover
          if (updatedArrServiceIdCover[matchedServiceIdCoverIndex].arrCover.length === 0) {
            updatedArrServiceIdCover.splice(matchedServiceIdCoverIndex, 1);
          }
        }
        // If cover is being updated
        else {
          updatedArrServiceIdCover[matchedServiceIdCoverIndex].arrCover[matchedCoverIndex] = cover;
        }
      }
      // If cover is being created
      else {
        updatedArrServiceIdCover[matchedServiceIdCoverIndex].arrCover =
          updatedArrServiceIdCover[matchedServiceIdCoverIndex].arrCover.concat(cover);
      }
    }
    // If serviceId does not yet exist in serviceIdCoverIndex
    else {
      updatedArrServiceIdCover.push({ serviceId: cover.serviceId, arrCover: [cover] });
    }
  });
  return updatedArrServiceIdCover;
};

class CoverInput extends PureComponent {
  constructor(props) {
    super(props);

    const {
      arrLocationWithNonProductionService,
      arrNonProductionService,
      mapProjectStartDateByServiceId,
    } = getArrLocationServiceArrServiceAndMapProjectStartDateByServiceId(
      props.arrRestaurantService
    );
    const selectedMonthYear = moment
      .utc()
      .utcOffset(props.arrRestaurantService[0].arrLocationService[0].arrService[0].offset)
      .format('MMM YYYY');
    this.state = {
      isDatePickerModalOpen: false,
      selectedMonthYear,

      // All data belonging to the user
      arrLocationWithNonProductionService,
      arrNonProductionService,
      arrServiceIdCover: [],
      mapProjectStartDateByServiceId,

      // Table parameters for a selected location
      arrColumn: [],
      arrRow: [],
      mapColumnIndexByColumnId: new Map(),
      mapNewCoverByDateAndServiceId: new Map(),
      mapUpdateCoverByDateAndServiceId: new Map(),
      mapArrUnchangedCoverByServiceId: new Map(),

      // For save changes
      isOpenWarningDialog: false,
      isOpenAlertMessageForNoCoverToModifyDialog: false,

      // For updating of monthYear with unsaved changes
      isOpenUpdateMonthYearWithUnsavedChangesDialog: false,
      updateMonthYearCallback: null,

      // For grouping of services
      mapArrServiceIdByColumnId: new Map(),
      mapMapCoverByServiceIdByDate: new Map(),

      // For opening and closing tooltip
      isTooltipOpened: false,
    };
    this.previouslySelectedMonthYear = selectedMonthYear;
    this.maxSelectedMonthYear = selectedMonthYear;
  }

  componentDidMount() {
    document.title = 'Cover Input | Lumitics | Towards Zero Food Waste';
    const { impersonatorIsCompanyUser, openSnackbar, setPageHistory } = this.context;
    const { auth0, history } = this.props;
    const { selectedMonthYear, arrNonProductionService } = this.state;

    const { user } = auth0;

    if ((!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser)) {
      openSnackbar('Please note that you do not have the access to cover input page!', 'error');
      history.push('/');
    } else {
      setPageHistory([CONSTANT.coverInputPage]);
      this.fetchCover(arrNonProductionService, selectedMonthYear);
    }
  }

  componentDidUpdate() {
    const { selectedMonthYear, arrNonProductionService } = this.state;
    const { arrRestaurantService } = this.props;

    // Just set the maxSelectedMonthYear everytime the component renders
    this.maxSelectedMonthYear = moment
      .utc()
      .utcOffset(arrRestaurantService[0].arrLocationService[0].arrService[0].offset)
      .format('MMM YYYY');

    if (this.previouslySelectedMonthYear !== selectedMonthYear) {
      this.previouslySelectedMonthYear = selectedMonthYear;
      this.fetchCover(arrNonProductionService, selectedMonthYear);
    }
  }

  onClickAwayTooltip() {
    this.closeTooltip();
  }

  onClickInfoIcon() {
    const { isTooltipOpened } = this.state;

    this.setState({ isTooltipOpened: !isTooltipOpened });
  }

  onCloseTooltip() {
    this.closeTooltip();
  }

  /**
   * Function loops through mapMapCoverByServiceIdByDate to populate arrRow. All cover cells are number cells, there are some variations
   * among them:
   * 1. Editable cell - The value can be either a number, or '' (which will translate to NaN) to represent an empty cell
   * 2. Uneditable cell - Cells where the date is beyond today or before start date, or cells that already have a value
   * For client users, they may only CREATE covers on cells with no covers and must be between service's project start
   * date and current date.
   * For admin users, they may CREATE, EDIT and DELETE covers on any cells, except cells of after current date.
   * @param {Row[]} arrHeaderRow - Array of all service of the selected location
   * @param {Row[]} arrHeaderRow[0] - Header row for location
   * @param {Row[]} arrHeaderRow[1] - Header row for service
   * @param {Set<number>} setServiceId - Set of serviceId
   * @param {Map<string, Map<string, {value: number, coverId: number}>>} mapMapCoverByServiceIdByDate - Map of key: date and value: mapCoverByServiceId
   * @param {string} selectedMonthYear - Currently selected month year
   * @param {string} offset - Offset of the restaurant (assume that all the services of the restaurants that the user has access to are the same)
   * @param {Map<number, boolean>} mapIsValidByServiceId - Map of key: serviceId and value: isValid
   * @returns {Row[]} arrRow - Array of row, containing row information for each table row cell
   */
  getArrayTableRowData(
    arrHeaderRow,
    setServiceId,
    mapMapCoverByServiceIdByDate,
    selectedMonthYear,
    offset,
    mapIsValidByServiceId
  ) {
    const { auth0, classes } = this.props;
    const { mapProjectStartDateByServiceId } = this.state;
    const { user } = auth0;
    const arrRow = arrHeaderRow;
    const startOfMonthMoment = moment(`15 ${selectedMonthYear}`).startOf('month');
    const endOfMonthMoment = moment(`15 ${selectedMonthYear}`).endOf('month');
    const todayDate = moment.utc().utcOffset(offset).format('YYYY-MM-DD');
    const mapTotalCoverByServiceId = new Map(
      Array.from(setServiceId).map((serviceId) => [serviceId, 0])
    );
    const mapArrUnchangedCoverByServiceId = new Map(
      Array.from(setServiceId).map((serviceId) => [serviceId, []])
    );

    // rowId starts from 2 for table body rows because there are 2 header rows, and this rowId is
    // important for identifying the row in the arrRow for updateTable function
    let rowId = 2;
    while (startOfMonthMoment.isSameOrBefore(endOfMonthMoment)) {
      const date = startOfMonthMoment.format('YYYY-MM-DD');
      const dateDisplay = startOfMonthMoment.format('DD-MMM');
      const rowData = { rowId, cells: [] };
      if (date > todayDate) {
        rowData.cells.push({
          type: 'text',
          text: dateDisplay,
          nonEditable: true,
          className: `${classes.tableCellForInvalidDate}`,
        });
        setServiceId.forEach(() => {
          rowData.cells.push({
            type: 'number',
            value: '',
            nonEditable: true,
            className: `${classes.tableCellForInvalidDate}`,
          });
        });
      } else {
        rowData.cells.push({
          type: 'text',
          text: dateDisplay,
          nonEditable: true,
        });
        const mapCoverByServiceId = mapMapCoverByServiceIdByDate.get(date);

        setServiceId.forEach((serviceId) => {
          // If there is already a previous cover input
          if (mapCoverByServiceId && mapCoverByServiceId.get(serviceId)) {
            const { value, coverId } = mapCoverByServiceId.get(serviceId);
            rowData.cells.push({
              ...{
                type: 'number',
                value,
                // Logic to decide if user is allowed to update previously filled cells
                nonEditable: !user.isAdmin,
              },
              ...((date < mapProjectStartDateByServiceId.get(serviceId) ||
                !mapIsValidByServiceId.get(serviceId)) && {
                className: `${classes.tableCellForInvalidDate}`,
              }),
            });
            if (value !== EXCLUDE_WASTE_INDICATOR) {
              mapTotalCoverByServiceId.set(
                serviceId,
                mapTotalCoverByServiceId.get(serviceId) + value
              );
            }
            mapArrUnchangedCoverByServiceId.get(serviceId).push({ value, coverId });
          } else {
            rowData.cells.push({
              type: 'number',
              value: '',
              nonEditable:
                !user.isAdmin &&
                (date < mapProjectStartDateByServiceId.get(serviceId) ||
                  !mapIsValidByServiceId.get(serviceId)),
              className:
                (date < mapProjectStartDateByServiceId.get(serviceId) ||
                  !mapIsValidByServiceId.get(serviceId)) &&
                `${classes.tableCellForInvalidDate}`,
            });
            // '' is the value to indicate empty cell
            mapArrUnchangedCoverByServiceId.get(serviceId).push({ value: '' });
          }
        });
      }
      arrRow.push(rowData);
      startOfMonthMoment.add(1, 'day');
      rowId += 1;
    }

    // Append Total Covers row
    const totalCoverRowData = { rowId, cells: [] };
    totalCoverRowData.cells.push({
      type: 'text',
      text: 'Total Covers',
      nonEditable: true,
      className: `${classes.tableCellForTotalCover}`,
    });
    setServiceId.forEach((serviceId) => {
      totalCoverRowData.cells.push({
        type: 'number',
        value: mapTotalCoverByServiceId.get(serviceId),
        nonEditable: true,
        className: `totalCoverCell ${classes.tableCellForTotalCover}`,
      });
    });
    arrRow.push(totalCoverRowData);
    this.setState({ mapArrUnchangedCoverByServiceId });

    return arrRow;
  }

  /**
   * Function fetches the cover data for all the non-production service that the user has access to
   * @param {typedefs.Service[]} arrNonProductionService - Array of all non-production service belonging to the user
   * @param {string} selectedMonthYear - Currently selected month year
   */
  async fetchCover(arrNonProductionService, selectedMonthYear) {
    const { renderLoaderAnimation } = this.context;
    const { auth0, history } = this.props;
    const { openSnackbar } = this.context;
    try {
      renderLoaderAnimation(true);
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-cover',
        {
          userId,
          arrService: arrNonProductionService,
          startDate: moment(`15 ${selectedMonthYear}`).startOf('month').format('YYYY-MM-DD'),
          endDate: moment(`15 ${selectedMonthYear}`).endOf('month').format('YYYY-MM-DD'),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { arrServiceIdCover } = response.data;
      this.constructTableParameters(arrServiceIdCover);
      this.setState({
        arrServiceIdCover,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      renderLoaderAnimation(false);
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar('Unknown error during fetching cover data. Please notify admin.', 'error');
      }
    }
  }

  /**
   * Function constructs the table data required to populate the table, which includes the following:
   * 1. arrColumn - Array of object containing the columnId (name of the column) and the width of the column
   * 2. arrRow - Array of objects containing the rowId (number) and the cells (array of values for the row)
   * Function also resets the mapNewCoverByDateAndServiceId and mapUpdateCoverByDateAndServiceId every time it is called so that the
   * previous input cover values will be removed as the selected date range is changed
   * @param {typedefs.ServiceIdCover[]} arrServiceIdCover - Array containing objects of non-production serviceId and its array of cover objects, where it has all the service that the user has access to
   */
  constructTableParameters(arrServiceIdCover) {
    const { arrLocationWithNonProductionService, selectedMonthYear } = this.state;
    const {
      arrRestaurantService,
      mapArrExcludedNonProductionServiceIdByLocationIdServiceGroupString,
    } = this.props;

    const {
      arrColumn,
      arrHeaderRow,
      setServiceId,
      mapColumnIndexByColumnId,
      mapIsValidByServiceId,
      mapArrServiceIdByColumnId,
    } = getHeaderDetails(
      arrLocationWithNonProductionService,
      mapArrExcludedNonProductionServiceIdByLocationIdServiceGroupString
    );
    const mapMapCoverByServiceIdByDate = getMapMapCoverByServiceIdByDate(
      arrServiceIdCover,
      setServiceId
    );

    const arrRow = this.getArrayTableRowData(
      arrHeaderRow,
      setServiceId,
      mapMapCoverByServiceIdByDate,
      selectedMonthYear,
      arrRestaurantService[0].arrLocationService[0].arrService[0].offset,
      mapIsValidByServiceId
    );

    this.setState({
      arrColumn,
      arrRow,
      mapColumnIndexByColumnId,
      mapUpdateCoverByDateAndServiceId: new Map(),
      mapNewCoverByDateAndServiceId: new Map(), // This is to reset the map when a new date range is selected so that only changes displayed on the page are captured
      mapArrServiceIdByColumnId,
      mapMapCoverByServiceIdByDate,
    });
  }

  /**
   * This function is called on click of monthYear change buttons and calendar selection.
   * mapNewCoverByDateAndServiceId and mapUpdateCoverByDateAndServiceId are checked to ensure that no unsaved changes exist.
   * If unsaved changes exist, update-month-year-with-unsaved-changes dialog is opened with the 'Confirm' button invoking the
   * callback function passed in this function.
   * @param {UpdateMonthYearCallback} updateMonthYearCallback - callback function to be invoked when user selects 'Confirm' button
   */
  changeSelectedMonthYear(updateMonthYearCallback) {
    const { mapNewCoverByDateAndServiceId, mapUpdateCoverByDateAndServiceId } = this.state;
    const arrCoverToBeCreated = Array.from(mapNewCoverByDateAndServiceId.values());
    const arrCoverToBeUpdated = Array.from(mapUpdateCoverByDateAndServiceId.values());
    if (arrCoverToBeCreated.length !== 0 || arrCoverToBeUpdated.length !== 0) {
      this.openOrCloseUpdateMonthYearWithUnsavedChangesDialog(true, updateMonthYearCallback);
    } else {
      updateMonthYearCallback();
    }
  }

  /** Function updates the new selected month year to input the new cover values for to a month before or after
   * @param {number} change - Number that is -1 or 1. -1 means the new selected month is a month before the current one, and 1 means the new selected month is a month after the current one.
   */
  changeSelectedMonthYearByClickingArrow(change) {
    const { selectedMonthYear } = this.state;
    const newSelectedMonthYear = moment(`15 ${selectedMonthYear}`)
      .add(change, 'months')
      .format('MMM YYYY');
    this.setState({
      selectedMonthYear: newSelectedMonthYear,
    });
  }

  /** Function updates the new selected month year to input the new cover values for, and closes the date picker modal
   * @param {date} newSelectedMonthYearInUnixTimestamp - New selected month year in unix timestamp
   */
  changeSelectedMonthYearByClickingFromCalendar(newSelectedMonthYearInUnixTimestamp) {
    const newSelectedMonthYear = newSelectedMonthYearInUnixTimestamp.format('MMM YYYY');
    this.setState({
      selectedMonthYear: newSelectedMonthYear,
      isDatePickerModalOpen: false,
    });
  }

  /** Function updates the boolean state of isDatePickerModalOpen, to open or close the date picker modal
   */
  openOrCloseDatePickerModal() {
    const { isDatePickerModalOpen } = this.state;
    this.setState({ isDatePickerModalOpen: !isDatePickerModalOpen });
  }

  /**
   * Function sends the cover(s) to be added to Backend. Upon successful creating of the new cover(s), it will update the arrServiceIdCover
   * and repopulate the table so that the newly added cover(s) are displayed and the cells are not allowed for edit.
   */
  async saveChanges() {
    // To close the warning dialog
    this.openOrCloseWarningDialog(false);
    const { openSnackbar, renderLoaderAnimation } = this.context;
    const { auth0, history } = this.props;
    const { arrServiceIdCover, mapArrServiceIdByColumnId, mapMapCoverByServiceIdByDate } =
      this.state;
    try {
      const { mapNewCoverByDateAndServiceId, mapUpdateCoverByDateAndServiceId } = this.state;
      const arrCoverToBeCreated = Array.from(mapNewCoverByDateAndServiceId.values());
      const arrCoverToBeUpdated = Array.from(mapUpdateCoverByDateAndServiceId.values());
      if (mapArrServiceIdByColumnId.size !== 0) {
        [...arrCoverToBeCreated].forEach((coverToBeCreated) => {
          const arrServiceIdOfGroup = mapArrServiceIdByColumnId.get(coverToBeCreated.serviceId);
          if (arrServiceIdOfGroup) {
            arrServiceIdOfGroup.forEach((serviceId) => {
              arrCoverToBeCreated.push({ ...coverToBeCreated, serviceId });
            });
          }
        });
        [...arrCoverToBeUpdated].forEach((coverToBeUpdated) => {
          const arrServiceIdOfGroup = mapArrServiceIdByColumnId.get(coverToBeUpdated.serviceId);
          if (arrServiceIdOfGroup) {
            arrServiceIdOfGroup.forEach((serviceId) => {
              const cover = mapMapCoverByServiceIdByDate.get(coverToBeUpdated.date).get(serviceId);
              if (cover) {
                arrCoverToBeUpdated.push({
                  ...coverToBeUpdated,
                  serviceId,
                  coverId: cover.coverId,
                });
              }
            });
          }
        });
      }
      if (arrCoverToBeCreated.length === 0 && arrCoverToBeUpdated.length === 0) {
        this.openOrCloseAlertMessageForNoCoverToModifyDialog(true);
      } else {
        renderLoaderAnimation(true);
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        const requestBody = {
          userId,
          arrCoverToBeCreated,
        };
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
          // Additional check that only if user is admin, then append arrCoverToBeUpdated to requestBody
          requestBody.arrCoverToBeUpdated = arrCoverToBeUpdated;
        }
        const response = await axios.post('/api/modify-cover', requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { arrCover } = response.data;
        const updatedArrServiceIdCover = updateArrServiceIdCoverAfterModifying(
          arrCover,
          arrServiceIdCover
        );
        this.constructTableParameters(updatedArrServiceIdCover);
        this.setState({
          arrServiceIdCover: updatedArrServiceIdCover,
        });
      }
      renderLoaderAnimation(false);
    } catch (error) {
      renderLoaderAnimation(false);
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar('Unknown error during updating cover data. Please notify admin.', 'error');
      }
    }
  }

  /**
   * Function is called on change of table. It always provides an array such that multiple actions (such as a copy-paste) is structured to be able to run .forEach
   * @param {CellChange[]} changes
   */
  updateTable(changes) {
    const {
      arrRow,
      mapColumnIndexByColumnId,
      mapNewCoverByDateAndServiceId,
      selectedMonthYear,
      mapArrUnchangedCoverByServiceId,
      mapUpdateCoverByDateAndServiceId,
    } = this.state;
    const { classes } = this.props;
    const arrUpdatedRow = [...arrRow];
    const updatedMapCoverByDateAndServiceIdForCreate = new Map(mapNewCoverByDateAndServiceId);
    const updatedMapCoverByDateAndServiceIdForUpdate = new Map(mapUpdateCoverByDateAndServiceId);
    changes.forEach((change) => {
      const { rowId, columnId, newCell, previousCell } = change;
      const columnIndex = mapColumnIndexByColumnId.get(columnId);
      // rowId - 2 as first 2 rows are location and service headers
      const arrUnchangedCover = mapArrUnchangedCoverByServiceId.get(columnId);
      const { value: unchangedCover, coverId } = arrUnchangedCover[rowId - 2];
      // text attribute from newCell and previousCell is used for comparison later on because empty cell has a text attribute value of '' and a
      // value of NaN, to better compare with unchangedCover which is '' if there is no cover created yet, and for easier code
      // calculations
      const { value: newValue, text: newText } = newCell;
      const { value: previousValue, className: previousClassName } = previousCell;
      const totalCoverCell = arrUpdatedRow[arrUpdatedRow.length - 1].cells[columnIndex];
      // Invalid cells are styled as greyed out cells. This check is done to reapply the greyed out styling to cells that
      // have been updated by the user.
      const isInvalidDateCell =
        typeof previousClassName === 'string' &&
        previousClassName.includes('tableCellForInvalidDate');
      if (!Number.isNaN(newValue) && newValue >= EXCLUDE_WASTE_INDICATOR) {
        // If new input is same as unchanged value, do not apply new value style
        if (newValue === unchangedCover || newText === unchangedCover) {
          arrUpdatedRow[rowId].cells[columnIndex] = {
            type: 'number',
            value: newValue,
            className: `${isInvalidDateCell && classes.tableCellForInvalidDate}`,
          };
        } else {
          arrUpdatedRow[rowId].cells[columnIndex] = {
            type: 'number',
            value: newValue,
            className: `${classes.tableCellWithNewValue} ${
              isInvalidDateCell && classes.tableCellForInvalidDate
            }`,
          };
        }
        // Increment / decrement total cover if new covers are input
        const newValueToBeAddedToTotal = newValue === EXCLUDE_WASTE_INDICATOR ? 0 : newValue;
        const previousValueToBeAddedToTotal =
          previousValue === EXCLUDE_WASTE_INDICATOR || Number.isNaN(previousValue)
            ? 0
            : previousValue;
        totalCoverCell.value =
          totalCoverCell.value + newValueToBeAddedToTotal - previousValueToBeAddedToTotal;
      } else {
        arrUpdatedRow[rowId].cells[columnIndex] = {
          type: 'number',
          value: '',
          className: `${isInvalidDateCell && classes.tableCellForInvalidDate}`,
        };
        // Decrement total cover by previous value if new figure is isNaN
        const previousValueToBeAddedToTotal =
          previousValue === EXCLUDE_WASTE_INDICATOR || Number.isNaN(previousValue)
            ? 0
            : previousValue;
        totalCoverCell.value =
          arrUpdatedRow[arrUpdatedRow.length - 1].cells[columnIndex].value -
          previousValueToBeAddedToTotal;
      }
      // Update total cover cell styling if there is no cell change for that service
      if (checkForCoverChangeIfZeroTotalCover(columnIndex, arrRow, arrUnchangedCover)) {
        totalCoverCell.className = `totalCoverCell ${classes.tableCellForTotalCover}`;
      } else {
        totalCoverCell.className = `totalCoverCell ${classes.tableCellWithNewValue}`;
      }
      // The first index of arrRow[rowId].cells is always the row header
      const currentDate = arrUpdatedRow[rowId].cells[0].text;

      // A valid create/update/destroy only occurs when
      // 1. newText is not an empty string (this means that there is a valid number), and the newValue does not equal to unchangedCover OR
      // 2. newText is an empty string (this means that the cell is empty), but the unchangedCover is not empty
      if (
        (newText !== '' && newValue !== unchangedCover && newValue >= EXCLUDE_WASTE_INDICATOR) ||
        (newText === '' && unchangedCover !== '')
      ) {
        const dateInYYYYMMDDFormat = moment(`${currentDate} ${selectedMonthYear.slice(-4)}`).format(
          'YYYY-MM-DD'
        );
        const coverForUpdate = {
          date: dateInYYYYMMDDFormat,
          value: Number.isNaN(newValue) ? DELETE_COVER_INDICATOR : newValue,
          serviceId: columnId,
        };
        // Create happens if the original unchangedCover is '', else it is an update
        if (unchangedCover === '') {
          updatedMapCoverByDateAndServiceIdForCreate.set(
            `${currentDate}_${columnId}`,
            coverForUpdate
          );
        } else {
          coverForUpdate.coverId = coverId;
          updatedMapCoverByDateAndServiceIdForUpdate.set(
            `${currentDate}_${columnId}`,
            coverForUpdate
          );
        }
      } else {
        // If newInput is not a valid create/update, then clear them from map create and update
        updatedMapCoverByDateAndServiceIdForCreate.delete(`${currentDate}_${columnId}`);
        updatedMapCoverByDateAndServiceIdForUpdate.delete(`${currentDate}_${columnId}`);
      }
    });
    this.setState({
      arrRow: arrUpdatedRow,
      mapNewCoverByDateAndServiceId: updatedMapCoverByDateAndServiceIdForCreate,
      mapUpdateCoverByDateAndServiceId: updatedMapCoverByDateAndServiceIdForUpdate,
    });
  }

  /** Function updates the boolean state of isOpenWarningDialog, to open or close the warning dialog
   * @param {boolean} newIsOpenWarningDialog - New isOpenWarningDialog to update
   */
  openOrCloseWarningDialog(newIsOpenWarningDialog) {
    this.setState({
      isOpenWarningDialog: newIsOpenWarningDialog,
    });
  }

  /** Function updates the boolean state of isOpenAlertMessageForNoCoverToModifyDialog, to open or close the alert message for no-cover-to-modify dialog
   * @param {boolean} newIsOpenAlertMessageForNoCoverToModifyDialog - New isOpenAlertMessageForNoCoverToModifyDialog to update
   */
  openOrCloseAlertMessageForNoCoverToModifyDialog(newIsOpenAlertMessageForNoCoverToModifyDialog) {
    this.setState({
      isOpenAlertMessageForNoCoverToModifyDialog: newIsOpenAlertMessageForNoCoverToModifyDialog,
    });
  }

  /** This function updates the boolean state of isOpenUpdateMonthYearWithUnsavedChangesDialog, to open or close the alert message for update-month-year-with-unsaved-changes dialog,
   * and updateMonthYearCallback function state, a callback function to be invoked if a user wishes to discard their changes
   * @param {boolean} newIsOpenUpdateMonthYearWithUnsavedChangesDialog - New isOpenUpdateMonthYearWithUnsavedChangesDialog to update
   * @param {UpdateMonthYearCallback} newUpdateMonthYearCallback - New updateMonthYearCallback to update
   */
  openOrCloseUpdateMonthYearWithUnsavedChangesDialog(
    newIsOpenUpdateMonthYearWithUnsavedChangesDialog,
    newUpdateMonthYearCallback
  ) {
    if (newIsOpenUpdateMonthYearWithUnsavedChangesDialog) {
      this.setState({
        isOpenUpdateMonthYearWithUnsavedChangesDialog:
          newIsOpenUpdateMonthYearWithUnsavedChangesDialog,
        updateMonthYearCallback: newUpdateMonthYearCallback,
      });
    } else {
      this.setState({
        isOpenUpdateMonthYearWithUnsavedChangesDialog:
          newIsOpenUpdateMonthYearWithUnsavedChangesDialog,
        updateMonthYearCallback: null,
      });
    }
  }

  closeTooltip() {
    this.setState({ isTooltipOpened: false });
  }

  render() {
    const {
      isDatePickerModalOpen,
      isOpenAlertMessageForNoCoverToModifyDialog,
      isOpenWarningDialog,
      isTooltipOpened,
      selectedMonthYear,
      arrColumn,
      arrRow,
      isOpenUpdateMonthYearWithUnsavedChangesDialog,
      updateMonthYearCallback,
    } = this.state;
    const { classes } = this.props;

    return (
      <Box position="relative" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="absolute"
          width="100%"
          padding="20px 0px"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            spacing={0}
            className={classes.buttonHeaderRow}
          >
            <Grid item xs={8}>
              <Button
                onClick={() =>
                  this.changeSelectedMonthYear(() =>
                    this.changeSelectedMonthYearByClickingArrow(-1)
                  )
                }
              >
                <KeyboardArrowLeftIcon />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonStyle}
                onClick={() => this.openOrCloseDatePickerModal()}
              >
                <Typography variant="body2">{`${selectedMonthYear}`}</Typography>
              </Button>
              <Button
                onClick={() =>
                  this.changeSelectedMonthYear(() => this.changeSelectedMonthYearByClickingArrow(1))
                }
                disabled={selectedMonthYear === this.maxSelectedMonthYear}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                className={`saveChangesButton ${classes.saveChangesButtonStyle}`}
                startIcon={<CheckCircleOutlineSharpIcon />}
                onClick={() => this.openOrCloseWarningDialog(true)}
              >
                <Typography variant="body2">Save Changes</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" className={classes.reactGridOuterGrid}>
            <ClickAwayListener onClickAway={() => this.onClickAwayTooltip()}>
              <Tooltip
                open={isTooltipOpened}
                onClose={() => this.onCloseTooltip()}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                title={
                  <Typography variant="caption">
                    <p>
                      Please input the corresponding special cover value according to the situation:
                    </p>
                    <p>-1: Restaurant is opened but there is issue with tracking waste</p>
                    <p>0: Restaurant is closed</p>
                    <p>Else, input the correct cover for the day</p>
                  </Typography>
                }
              >
                <InfoIcon onClick={() => this.onClickInfoIcon()} className={classes.tooltipIcon} />
              </Tooltip>
            </ClickAwayListener>
            <Grid item className={classes.reactGridInnerGrid}>
              {arrColumn.length !== 0 && (
                <ReactGrid
                  rows={arrRow}
                  columns={arrColumn}
                  onCellsChanged={(changes) => this.updateTable(changes)}
                  stickyTopRows={2}
                  enableRangeSelection
                />
              )}
            </Grid>
          </Grid>
          {/* Modal for warning message upon updating monthYear with unsaved changes */}
          <Dialog
            open={isOpenUpdateMonthYearWithUnsavedChangesDialog}
            onClose={(event, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                this.openOrCloseUpdateMonthYearWithUnsavedChangesDialog(false);
              }
            }}
            aria-labelledby="warning-dialog-title"
            aria-describedby="warning-dialog-description"
          >
            <DialogTitle
              id="warning-dialog-title"
              className={classes.warningTitle}
              disableTypography="true"
            >
              WARNING
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="warning-dialog-description">
                <p>There are unsaved changes in this page.</p>
                <p>
                  Please click &#x27;<b>CONFIRM</b>&#x27; if you would like to discard the changes
                  and proceed, or click &#x27;
                  <b>CANCEL</b>&#x27; if you would like to review your changes again.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={() => this.openOrCloseUpdateMonthYearWithUnsavedChangesDialog(false)}
              >
                Cancel
              </Button>
              <Button
                className={`confirmButton ${classes.confirmButton}`}
                onClick={() => {
                  updateMonthYearCallback();
                  this.openOrCloseUpdateMonthYearWithUnsavedChangesDialog(false);
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/* Modal for warning message upon clicking 'Save Changes' */}
          <Dialog
            open={isOpenWarningDialog}
            onClose={(event, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                this.openOrCloseWarningDialog(false);
              }
            }}
            aria-labelledby="warning-dialog-title"
            aria-describedby="warning-dialog-description"
          >
            <DialogTitle
              id="warning-dialog-title"
              className={classes.warningTitle}
              disableTypography="true"
            >
              WARNING
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="warning-dialog-description">
                <p>
                  Please ensure covers submitted are correct as changes are final and irreversible.
                </p>
                <p>
                  Please click &#x27;<b>CONFIRM</b>&#x27; if you want to submit the changes, and
                  click &#x27;
                  <b>CANCEL</b>&#x27; if you would like to check the changes again.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={() => this.openOrCloseWarningDialog(false)}
              >
                Cancel
              </Button>
              <Button
                className={`confirmButton ${classes.confirmButton}`}
                onClick={() => this.saveChanges()}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/* Modal for alert message upon clicking 'Save Changes' and 'Confirm' but there is actually no cover change was input */}
          <Dialog
            open={isOpenAlertMessageForNoCoverToModifyDialog}
            onClose={(event, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                this.openOrCloseAlertMessageForNoCoverToModifyDialog(false);
              }
            }}
            aria-labelledby="alert-message-dialog-title"
            aria-describedby="alert-message-dialog-description"
          >
            <DialogTitle
              id="alert-message-dialog-title"
              className={classes.warningTitle}
              disableTypography="true"
            >
              NOTE
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-message-dialog-description">
                No input for cover found!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={() => this.openOrCloseAlertMessageForNoCoverToModifyDialog(false)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        {/* Modal for the date picker */}
        {isDatePickerModalOpen && (
          <Box className={classes.calendarBox}>
            <Calendar
              selected={selectedMonthYear}
              onlyMonthPicker
              showMonthYearPicker
              onChange={(newSelectedMonthYearInUnixTimestamp) =>
                this.changeSelectedMonthYear(() =>
                  this.changeSelectedMonthYearByClickingFromCalendar(
                    newSelectedMonthYearInUnixTimestamp
                  )
                )
              }
              maxDate={moment(`15 ${this.maxSelectedMonthYear}`).toDate()}
              // ref={calendarRef}
              format="MMMM YYYY"
            />
          </Box>
        )}
      </Box>
    );
  }
}

CoverInput.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(CoverInput)));
