import React, { PureComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
// Styling
import { withStyles, withTheme } from '@material-ui/core/styles';
// Chart Library
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  ARR_RANDOM_COLOR,
  BAR_CHART_COLORS_FOR_ALL_SERVICES,
  INVALID_WASTE_PER_COVER_VALUE,
} from './Constants';
import { AppContext, CONSTANT } from '../../../AppContext';

Chart.register(...registerables, ChartDataLabels);

const PADDING_FOR_WASTE_PER_COVER_X_AXIS = 80;
const THICKNESS_OF_BAR_FOR_WASTE_PER_COVER = 36;
const WASTE_PER_COVER_BAR_CHART_HEIGHT =
  CONSTANT.defaultBarChartHeight + PADDING_FOR_WASTE_PER_COVER_X_AXIS + 65;
// Todo: This is hardcored for now cos cant find a way to read the parent's width.
// Solutions found online didnt seem to work, havent figured out a way to make it work
// The value 621 is taken as an estimate of the parent component that houses BarChart component (Ref: #84)
const PARENT_COMPONENT_WIDTH = 621;

const styles = (theme) => ({
  alignCenter: {
    textAlign: 'center',
  },
  legendItem: {
    paddingRight: 10,
    color: CONSTANT.defaultAxisColor,
  },
  legendColorBox: {
    marginRight: '4px',
    width: '8px',
    height: '8px',
    display: 'inline-block',
  },
  legendText: {
    display: 'inline',
    ...theme.typography.body2,
  },
});

/**
 * Check if the two legends are equal. This function is used to check if the bar chart needs to be updated.
 * @param {string} arrLegendA - Array of legend A
 * @param {string} arrLegendB - Array of legend B
 * @returns {boolean} Boolean value to indicate if the two legends are equal
 */
const checkEqualLegends = (arrLegendA, arrLegendB) => {
  if (arrLegendA.length !== arrLegendB.length) {
    return false;
  }
  for (let arrLegendAIndex = 0; arrLegendAIndex < arrLegendA.length; arrLegendAIndex += 1) {
    if (arrLegendA[arrLegendAIndex] !== arrLegendB[arrLegendAIndex]) {
      return false;
    }
  }
  return true;
};

/**
 * Returns the pastel colours based on the total number of legend keys for each bar in the bar chart.
 * The maximum number of colours available is 10.
 * @param {number} numberOfLegendKeys - Number of legend keys
 */
const getPastelColors = (numberOfLegendKeys) => {
  try {
    return CONSTANT.pastelColorArray.slice(0, numberOfLegendKeys);
  } catch (err) {
    // To do: This used to be a console log. Change to alert tentatively to retain the catch.
    alert('Too many services.');
    return null;
  }
};

/**
 * Returns the chart colors for the corresponding legend (serviceName)
 * @param arrLegend - Array of legend keys
 * @returns arrChartColor - Array of chart colors for the legends
 */
const getArrChartColor = (arrLegend) => {
  const arrChartColor = [];

  // This is for the location's total graph where the arrLegend is a one element array and the element is ''
  if (arrLegend.length === 1 && arrLegend[0] === '') {
    arrChartColor.push(ARR_RANDOM_COLOR[0]);
  } else {
    let iterationForUnfoundLegend = 1;
    arrLegend.forEach((legend) => {
      let barChartColorsForLegend = BAR_CHART_COLORS_FOR_ALL_SERVICES[legend.toLowerCase()];
      if (!barChartColorsForLegend) {
        barChartColorsForLegend =
          BAR_CHART_COLORS_FOR_ALL_SERVICES[`option ${iterationForUnfoundLegend}`];
        // Maximum number of colors catered for unfound services is 5
        iterationForUnfoundLegend += iterationForUnfoundLegend === 5 ? 0 : 1;
      }
      arrChartColor.push(barChartColorsForLegend.default);
    });
  }

  return arrChartColor;
};

/**
 * Returns the styling for the chart wrapper of the bar chart. Chart wrapper for all bar charts only have styling for the
 * height, except the one for Waste Per Cover. This is because the height of the chart wrapper for Waste Per Cover has to
 * cater for the double x-axis (height), and allow overflowing to prevent the bars from overlapping each other (width).
 * Note: If the calculated width for Waste Per Cover bar graph is lesser than the width of the parent component, take the
 * parent component's width so that the graph fills up the space
 * @param {number} numberOfLabelData - Number of label data
 * @param {number} numberOfXAxisData - Number of x axis data
 * @param {boolean} isWastePerCover - Indicates if the bar chart is for Waste Per Cover
 * @returns {Object} Object - Object containing styling for Waste Per Cover
 * @returns {number} Object.height - Height of chart wrapper
 * @returns {number} Object.width - Width of chart wrapper
 */
const getClassForChartWrapper = (numberOfLabelData, numberOfXAxisData, isWastePerCover) => {
  if (isWastePerCover) {
    // +3 is to cater space to the left and right side of each x-axis data, so that each group of bars
    // are more separated. This also helps to prevent the very first bar from starting too close to the
    // y-axis, which will cause the label to be cut off
    const widthOfWastePerCover =
      (numberOfLabelData + 3) * numberOfXAxisData * THICKNESS_OF_BAR_FOR_WASTE_PER_COVER;
    return {
      height: WASTE_PER_COVER_BAR_CHART_HEIGHT,
      width: widthOfWastePerCover > PARENT_COMPONENT_WIDTH ? widthOfWastePerCover : undefined,
    };
  }
  return {
    height: CONSTANT.defaultBarChartHeight,
  };
};

class BarChart extends PureComponent {
  constructor(props) {
    super(props);

    this.chartReference = React.createRef();
    this.state = {
      height: props.isWastePerCover
        ? WASTE_PER_COVER_BAR_CHART_HEIGHT
        : CONSTANT.defaultBarChartHeight,
      width: '95%',
      chartOption: this.createChartOption(),
      chartData: this.createDataSet(),
      arrLegend: props.labelData,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateChart(prevProps, prevState);
  }

  /**
   * Get the font size for the y-axis, x-axis and scale label.
   * Returns:
   *    mobile view: 10
   *    desktop view < 1280px or in keyoverview or in station breakdown: 12
   *    else: 14
   * @returns {number} Number - Size of axis font: 10, 12 or 14
   */
  getAxisFontSize() {
    const { isRestaurantPerformance, isStationBreakdown, isWastePerCover, theme } = this.props;

    if (window.innerWidth < theme.breakpoints.values.md) {
      return 10;
    }
    if (
      window.innerWidth < theme.breakpoints.values.lg ||
      isRestaurantPerformance ||
      isStationBreakdown ||
      isWastePerCover
    ) {
      return 12;
    }
    return 14;
  }

  /**
   * Gets the colour for the labels on both the y and x axes.
   * Returns white if the chart is in the Key Overview card, else returns dark gray.
   * @returns {string} String - Colour code: white or dark gray
   */
  getTicksColor() {
    const { isRestaurantPerformance, isWastePerCover } = this.props;

    if (isRestaurantPerformance || isWastePerCover) {
      return CONSTANT.whiteColor;
    }

    return CONSTANT.defaultAxisColor;
  }

  /**
   * Gets the colour for the gridlines of the background of the charts.
   * Returns dark gray if chart is in Key Overview card, else returns white-gray.
   * @returns {string} String - Colour code: dark gray or white-gray
   */
  getGridlineColor() {
    const { isRestaurantPerformance, isWastePerCover } = this.props;

    if (isRestaurantPerformance || isWastePerCover) {
      return CONSTANT.keyOverviewGridlineColor;
    }

    return CONSTANT.defaultGridlineColor;
  }

  /**
   * Gets the colour for the x axis.
   * Returns white if chart is in Key Overview card, else returns gray.
   * @returns {string} String - Colour code: wite or gray
   */
  getZeroLineColor() {
    const { isRestaurantPerformance, isWastePerCover } = this.props;

    return isRestaurantPerformance || isWastePerCover
      ? CONSTANT.whiteColor
      : CONSTANT.defaultZerolineColor;
  }

  /**
   * Gets the axis colours based on which component called the chart.
   * Returns light gray if chart is in Key Overview card, else returns dark gray.
   * @returns {string} String - Colour code: light gray or dark gray
   */
  getAxisColor() {
    const { isRestaurantPerformance, isWastePerCover } = this.props;

    return isRestaurantPerformance || isWastePerCover
      ? CONSTANT.keyOverviewAxisColor
      : CONSTANT.defaultAxisColor;
  }

  /**
   * Returns the colours for the bar charts used in the Key Overview card based on the value.
   * If data is negative, returns the coral colour, else return green.
   * @returns {string[]} Array of colour code: coral or green
   */
  getKeyOverviewColors() {
    const { yAxisData } = this.props;

    return yAxisData[0].map((yAxisValue) =>
      yAxisValue >= 0 ? CONSTANT.keyOverviewCoralColor : CONSTANT.keyOverviewGreenColor
    );
  }

  /**
   * Update the bar chart with the new data if there is a change in either the bar chart data
   */
  updateChart(prevProps, prevState) {
    const { labelData, xAxisData, yAxisData, yAxisLabel } = this.props;
    const { arrLegend } = this.state;

    if (
      xAxisData !== prevProps.xAxisData ||
      yAxisData !== prevProps.yAxisData ||
      labelData !== prevProps.labelData ||
      yAxisLabel !== prevProps.yAxisLabel ||
      !checkEqualLegends(arrLegend, prevState.arrLegend)
    ) {
      this.setState({
        chartOption: this.createChartOption(),
        chartData: this.createDataSet(),
        arrLegend: labelData,
      });
    }
  }

  /**
   * Create data in the structure required by the bar chart to display it correctly
   * @returns {Object} chartOption - Object containing chart options for the bar graph
   */
  createChartOption() {
    let currencyAbbreviaton = '';
    if (this.context) {
      ({ currency: currencyAbbreviaton } = this.context);
    }
    const { isRestaurantPerformance, isWastePerCover, yAxisData, yAxisLabel } = this.props;
    const chartOption = {
      maintainAspectRatio: false,
      responsive: true,
      // Options to style the x and y axes
      scales: {
        x: {
          ticks: {
            display: true,
            color: this.getTicksColor(),
            font: this.getAxisFontSize(),
            padding: isWastePerCover ? PADDING_FOR_WASTE_PER_COVER_X_AXIS : 0,
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          stacked: !isWastePerCover,
        },
        y: {
          suggestedMax: this.calculateMaxYTickValue(),
          suggestedMin: this.calculateMinYTickValue(),
          ticks: {
            display: true,
            maxTicksLimit: 6,
            font: this.getAxisFontSize(),
            color: this.getAxisColor(),
            padding: 6,
            beginAtZero: true,
          },
          grid: {
            display: true,
            color: (context) =>
              context.tick.value === 0 ? this.getZeroLineColor() : this.getGridlineColor(),
          },
          border: {
            display: false,
          },
          title: {
            display: true,
            text: yAxisLabel,
            font: this.getAxisFontSize(),
            color: this.getAxisColor(),
          },
          stacked: !isWastePerCover,
        },
      },
      layout: {
        autoPadding: true,
      },
      plugins: {
        legend: {
          display: false,
        },
        // Options for styling the tooltips
        tooltip: {
          enabled: this.isTooltipEnabled(),
          mode: 'index',
          bodyAlign: 'right',
          callbacks: {
            // Initialize the total for each day/week/month
            afterTitle: () => {
              window.total = 0;
            },
            // Calculate total within each day/week/month
            label: (context) => {
              const currentStack = context.dataset.label;
              const currentWaste = context.dataset.data[context.dataIndex];
              const currentWasteFormattedTo2dpAndWithCommaSeparation = Number(
                currentWaste.toFixed(2)
              ).toLocaleString('en-US', { minimumFractionDigits: 2 });

              window.total += context.raw;

              if (isRestaurantPerformance || isWastePerCover || yAxisData.length < 2) {
                return null;
              }

              if (yAxisLabel.includes('WEIGHT')) {
                return `${currentStack}: ${currentWasteFormattedTo2dpAndWithCommaSeparation} KG`;
              }

              return `${currentStack}: ${currencyAbbreviaton} ${currentWasteFormattedTo2dpAndWithCommaSeparation}`;
            },
            // Returns the total at the bottom of the tooltip
            footer: () => {
              const totalWasteFormattedTo2dpAndWithCommaSeparation = Number(
                window.total.toFixed(2)
              ).toLocaleString('en-US', { minimumFractionDigits: 2 });
              if (isRestaurantPerformance || isWastePerCover) {
                return `Total: ${totalWasteFormattedTo2dpAndWithCommaSeparation}`;
              }
              if (yAxisLabel.includes('WEIGHT')) {
                return `Station Total: ${totalWasteFormattedTo2dpAndWithCommaSeparation} KG`;
              }

              return `Station Total: ${currencyAbbreviaton} ${totalWasteFormattedTo2dpAndWithCommaSeparation}`;
            },
          },
        },
        // datalabels are only necessary for bar charts in key overview and waste per cover
        datalabels: {
          ...(isRestaurantPerformance || isWastePerCover
            ? {
                labels: {
                  // This is for the service labels for Waste Per Cover bar graph
                  title: {
                    color: CONSTANT.whiteColor,
                    offset: 8,
                    anchor: 'start',
                    align: 120,
                    rotation: -35,
                    padding: {
                      top: -20,
                    },
                    font: {
                      size: 12,
                    },
                    formatter: (value, context) => {
                      if (isWastePerCover && context.dataset.data[context.dataIndex] !== null) {
                        return context.dataset.label;
                      }
                      return '';
                    },
                  },
                  // This is for the data values for all key overview bar graphs
                  value: {
                    color: CONSTANT.whiteColor,
                    offset: 8,
                    anchor: (context) => {
                      const value = context.dataset.data[context.dataIndex];
                      return value >= 0 ? 'end' : 'start';
                    },
                    align: (context) => {
                      const value = context.dataset.data[context.dataIndex];
                      return value >= 0 ? 'end' : 'start';
                    },
                    padding: {
                      top: -20,
                    },
                    font: {
                      size: 12,
                    },
                    formatter: (value) => {
                      if (value !== null) {
                        // Magic number from backend WasteAnalysisForHighlights - INVALID_WASTE_PER_COVER_VALUE
                        // This magic number is to allow a small bar to be created so that the service name can be displayed
                        if (value === INVALID_WASTE_PER_COVER_VALUE) {
                          return '-';
                        }
                        let newValue = '';
                        newValue = Number(value.toFixed(2)).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        });
                        if (isWastePerCover) {
                          newValue = Math.round(value);
                        } else {
                          newValue = Number(value.toFixed(2)).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          });
                          if (newValue > 0) {
                            newValue = `+${newValue}`;
                          }
                        }
                        return newValue;
                      }
                      return null;
                    },
                  },
                },
              }
            : {
                display: false,
              }),
        },
      },
    };

    return chartOption;
  }

  /**
   * Calculates the maximum value of the y-axis tick for the bar chart. To find the maximum y tick
   * value for station breakdown related bar graphs, the waste data that belongs to the same x-axis value
   * are summed up and the max value is found based on the highest summed value. This is because the station
   * breakdown uses stacked bar graphs. However, Key Overview, normal bar graphs are used and hence the
   * max value is found based on the highest individual waste data.
   * @returns {number} Number - Maximum value of the y-axis tick
   */
  calculateMaxYTickValue() {
    const { yAxisData, isRestaurantPerformance, isWastePerCover } = this.props;

    if (yAxisData.length === 0) {
      return 0;
    }

    if (isRestaurantPerformance) {
      const arrWasteValueForABar = [];
      for (let i = 0; i < yAxisData[0].length; i += 1) {
        for (let j = 0; j < yAxisData.length; j += 1) {
          arrWasteValueForABar.push(yAxisData[j][i]);
        }
      }
      // To account for the data labels at the top of each bar
      return 1.05 * Math.max(...arrWasteValueForABar);
    }

    if (isWastePerCover) {
      const arrWasteValueForABar = [];
      for (let i = 0; i < yAxisData[0].length; i += 1) {
        for (let j = 0; j < yAxisData.length; j += 1) {
          arrWasteValueForABar.push(yAxisData[j][i]);
        }
      }
      // To account for the data labels at the top of each bar
      const suggestedMax = 1.05 * Math.max(...arrWasteValueForABar);
      // This check is to make sure that the Waste Per Cover bar graph has a minimal maximumm of 300 grams.
      // This is because invalid waste per cover for a service has a value of 0.000001. If the minimum
      // maximum is not set, these bars will become obvious if all the bars have a value of 0.000001.
      if (suggestedMax < 300) {
        return 300;
      }
      return suggestedMax;
    }

    const arrTotalWasteValuesForABar = [];
    for (let i = 0; i < yAxisData[0].length; i += 1) {
      let totalForCurrentBar = 0;
      for (let j = 0; j < yAxisData.length; j += 1) {
        totalForCurrentBar += yAxisData[j][i];
      }
      arrTotalWasteValuesForABar.push(totalForCurrentBar);
    }

    return Math.max(...arrTotalWasteValuesForABar);
  }

  /**
   * Calculates the minimum value of the y-axis tick for the bar chart. All bar charts are to
   * start from 0, except the Wastage Performance by Restaurant bar graph, because restaurant
   * wastage performance can be negative.
   * @returns {number} Number - Minimum value of the y-axis tick
   */
  calculateMinYTickValue() {
    const { yAxisData, isRestaurantPerformance } = this.props;

    if (isRestaurantPerformance) {
      const arrTotalWasteValuesForABar = [];
      for (let i = 0; i < yAxisData[0].length; i += 1) {
        for (let j = 0; j < yAxisData.length; j += 1) {
          arrTotalWasteValuesForABar.push(yAxisData[j][i]);
        }
      }
      const suggestedMin = Math.min(...arrTotalWasteValuesForABar);
      if (suggestedMin < 0) {
        // To account for the data labels at the bottom of a bar
        return 1.05 * suggestedMin;
      }
    }
    return 0; // removes negative axis
  }

  isTooltipEnabled() {
    const { isRestaurantPerformance, isWastePerCover } = this.props;

    return !(isRestaurantPerformance || isWastePerCover);
  }

  /**
   * Creates the data needed for both x and y axis of the bar chart
   * @returns {Object} chartData - Object containing data to plot bar chart
   */
  createDataSet() {
    const { xAxisData } = this.props;

    const chartData = {
      labels: xAxisData,
      datasets: this.createStackDataSet(),
    };

    return chartData;
  }

  /**
   * Creates the data needed to fill in the bar chart.
   * Data includes styling for the bars as well as the data points to be populated.
   * @returns {Object[]} arrDataset - Array of dataset, containing data values to plot the bar graph
   */
  createStackDataSet() {
    const {
      yAxisData,
      hasDedicatedChartColor,
      isRestaurantPerformance,
      isWastePerCover,
      labelData,
    } = this.props;
    let arrChartColor = [];

    // Colours used for the bars in the chart
    if (isRestaurantPerformance) {
      arrChartColor = this.getKeyOverviewColors();
    } else if (hasDedicatedChartColor) {
      arrChartColor = getArrChartColor(labelData);
    } else {
      arrChartColor = getPastelColors(yAxisData.length);
    }

    // Styling and data points for the chart
    const arrDataset = yAxisData.map((yAxisSet, index) => {
      const currentStack = {};
      currentStack.stack = isWastePerCover ? undefined : 'allDay';
      currentStack.label = labelData.length === 0 ? '' : labelData[index];
      currentStack.data = yAxisSet;
      currentStack.borderWidth = 0;
      currentStack.backgroundColor = isRestaurantPerformance ? arrChartColor : arrChartColor[index];
      currentStack.skipNull = isWastePerCover;
      currentStack.barThickness = isWastePerCover
        ? THICKNESS_OF_BAR_FOR_WASTE_PER_COVER
        : undefined;

      return currentStack;
    });

    return arrDataset;
  }

  renderLegend() {
    const { classes, hasDedicatedChartColor, isRestaurantPerformance, isWastePerCover } =
      this.props;
    const { arrLegend } = this.state;
    const arrChartColor = hasDedicatedChartColor
      ? getArrChartColor(arrLegend)
      : getPastelColors(arrLegend.length);

    if (!isRestaurantPerformance && !isWastePerCover && arrLegend.length >= 1) {
      return (
        <Box component="div" className={classes.alignCenter}>
          {arrLegend.length &&
            arrLegend.map((item, index) => (
              <Box component="span" key={item} className={classes.legendItem}>
                <Box
                  component="span"
                  className={classes.legendColorBox}
                  style={{
                    backgroundColor: arrChartColor[index],
                  }}
                />
                <Typography className={classes.legendText}>{item}</Typography>
              </Box>
            ))}
        </Box>
      );
    }
    return null;
  }

  render() {
    const { isWastePerCover, labelData, xAxisData } = this.props;
    const { chartData, chartOption, height, width } = this.state;

    return (
      <div style={getClassForChartWrapper(labelData.length, xAxisData.length, isWastePerCover)}>
        <Bar
          ref={this.chartReference}
          height={height}
          width={width}
          data={JSON.parse(JSON.stringify(chartData))}
          options={chartOption}
        />
        {this.renderLegend()}
      </div>
    );
  }
}

BarChart.contextType = AppContext;

export default withTheme(withStyles(styles)(BarChart));
