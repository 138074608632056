import { createMuiTheme } from '@material-ui/core/styles';

const fontFirstBreakpointWidth = 600;
const fontSecondBreakpointWidth = 1280;

const AppTheme = createMuiTheme({
  palette: {
    /**
     * Colour intentions natively supported and exposed by mui theme.
     * - Usage: AppTheme.palette.[colourIntention].[variant]
     *    - colorIntention: primary|secondary|info|success|error|warning
     *    - variant: main|light|dark|contrastText
     * - The light, dark and contrastText are automatically computed from its main
     *   colour, based on the specified contrastThreshold and tonalOffset values.
     */
    primary: {
      main: '#102547', // Navy Blue
      main2: '#2C5C9B',
      main3: '#4A89BB',
    },
    secondary: {
      main: '#63C6CA', // Teal
    },
    info: {
      main: '#EB706E', // Coral
    },
    success: {
      main: '#64DA77', // Green
    },
    // error: {},
    // warning: {},

    contrastThreshold: 3,
    tonalOffset: 0.2,

    /**
     * Our own custom colour intentions
     * - Usage: AppTheme.palette.[colourIntention]
     */
    globalBackground: '#F9F8FA',
    cardBackground: '#FFFFFF',
    primaryGradient: 'linear-gradient(90deg, #102547, #1D4486)',
    primary2: '#1D4486',
    primary3: '#6D8CAB',
    gray0: '#353535',
    gray1: '#686868',
    gray2: '#969696',
    gray3: '#BBBBBB',
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',

    /**
     * Headers: card titles, tab options, table headers
     */
    h1: {
      fontSize: 17,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 19,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 22,
      },
    },
    h2: {
      fontSize: 16,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 18,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 20,
      },
    },
    h3: {
      fontSize: 15,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 17,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 19,
      },
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 16,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 18,
      },
    },
    h5: {
      fontSize: 13,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 15,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 17,
      },
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 14,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 16,
      },
    },

    /**
     * Body: table rows, dropdown options, etc.
     */
    body1: {
      fontSize: 15,
      fontWeight: 400,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 17,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 19,
      },
    },
    body2: {
      fontSize: 13.5,
      fontWeight: 400,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 15.5,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 17.5,
      },
    },
    tableCell: {
      fontSize: 10.15,
      fontWeight: 400,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 13,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 14.75,
      },
    },

    /**
     * Subtitles: total values
     */
    subtitle1: {
      fontSize: 24,
      fontWeight: 700,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 26,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 28,
      },
    },
    subtitle2: {
      fontWeight: 300,
    },

    /**
     * Buttons default
     */
    button: {
      fontWeight: 400,
    },

    /**
     * Caption: tooltips
     */
    caption: {
      fontSize: 13,
      fontWeight: 400,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 15,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 17,
      },
    },

    /**
     * Overline
     */
    overline: {
      fontWeight: 300,
    },

    /**
     * Custom typography style
     */
    // Breadcrumbs
    breadcrumb: {
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'uppercase',
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 14,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 16,
      },
    },

    // Card titles
    spacedTitle: {
      letterSpacing: '1px',
    },

    // Weight-cost summary title and units
    weightCostSummary: {
      fontSize: 9.6,
      fontWeight: 400,
      lineHeight: '9.6px',
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 11.2,
        lineHeight: '11.2px',
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 12.8,
        lineHeight: '12.8px',
      },
    },

    notificationBtn: {
      fontSize: 10,
      fontWeight: 400,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 11,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 12,
      },
    },

    /**
     * Custom typography style for Key Overview
     */
    // Key overview chart title
    keyOverviewChartTitle: {
      fontSize: 13.5,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 15.5,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 17.5,
      },
    },
    keyOverviewShowMoreText: {
      fontSize: 10.5,
      fontWeight: 400,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 12,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 13.5,
      },
    },
    keyOverviewCostUnitText: {
      fontSize: 9.6,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 11.2,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 12.8,
      },
    },

    // Go to stations / food items links
    clickthroughLink: {
      fontSize: 10.5,
      fontWeight: 600,
      [`@media (min-width:${fontFirstBreakpointWidth}px)`]: {
        fontSize: 10.5,
      },
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        fontSize: 14,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  card: {
    /**
     * Styles used for cards on home page
     */
    home: {
      boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.11)',
      '&:first-child': {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      },
      '&:last-child': {
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
    },

    keyOverviewExpansionDetails: {
      display: 'block',
      paddingTop: '0px',
      paddingBottom: '10px',
      paddingLeft: '16px',
      paddingRight: '16px',
      marginTop: '-15px',
      [`@media (min-width:${fontSecondBreakpointWidth}px)`]: {
        paddingTop: '10px',
      },
    },
  },
  main: {
    /**
     * Padding for main pages (Home, StationsBreakdown, FoodItemBreakdown)
     */
    paddingLeftRight: '3.8%',
    paddingLeftRight2: '3.3%',
  },
  overrides: {
    /**
     * Override global MuiBreadcrumbs style
     */
    MuiBreadcrumbs: {
      separator: {
        marginLeft: '5px',
        marginRight: '5px',
      },
    },
    MuiTableCell: {
      body: {
        color: '#353535',
      },
    },
  },
});

export default AppTheme;
